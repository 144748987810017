import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import api from "../../store/Api/Intercepteur";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UseLanguage } from "../../store/User/language";
import translate from "../../store/Translation/Langue";

export const AddPoint = () => {
    const [showModal, setShowModal] = useState(false)
    const language = UseLanguage.use.language()
    const modalRef = useRef()
    const [type, setType] = useState("")
    const [image, setImage] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)

    const handleNotificationClose = () => {
        setShowModal(false);
        setImage(null)
    };

    const handleNotificationOpen = (typeFormation) => {
        setShowModal(true);
        setType(typeFormation)
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
            setSelectedImage(file)
        }
    };

    const addPointClicked = async () => {
        try {
            const formData = new FormData();
            formData.append("type", type);
            formData.append("image", selectedImage);

            await api.post('/screenShot', formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            toast.success("Paiement envoyé avec succès! Nous vous enverrons une notificqtion lors du validation",
                {
                    onClose: () => {
                        setShowModal(false)
                    }
                }
            )
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleNotificationClose()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <div className="flex gap-x-16 min-h-[60vh] px-40 mt-20 mb-5 max-lg:flex-wrap gap-y-10 justify-center max-lg:px-16">
            <div className="border py-3 px-5 rounded-lg w-80">
                <p className="text-xl font-semibold text-black/80 my-2 h-14">{translate(language, "formation").toUpperCase()} </p>
                <p className="text-[14px] h-10 text-black/80">{translate(language, "decriptionFomartion")}</p>
                <img src="/image/BuyFormation.png" alt="formation" className="w-full h-60" />
                <div className="h-32 border-t pt-6 mb-5">
                    <p className="text-black/60">
                        <span className="text-4xl text-black/70 font-bold">$15 </span>
                    </p>
                    <button onClick={() => handleNotificationOpen("formation")} className="w-full rounded py-2 text-center text-white font-bold my-5 bg-primary">{translate(language, 'buy200Credit')}</button>
                </div>
            </div>
            <div className="border py-3 px-5 rounded-lg w-80 border-gray-300">
                <p className="text-xl font-semibold text-black/80 my-2 h-14">{translate(language, "formation").toUpperCase()}   + {translate(language, "certificate").toUpperCase()}</p>
                <p className="text-[14px] h-10 text-black/80">{translate(language, "decriptionFomartionCertificat")}</p>
                <div className="h-60 flex justify-center items-center">
                    <img src="/image/image.avif" alt="formation" className="w-[90%] h-full object-cover max-lg:w-[60%]" />
                </div>
                <div className="h-32 pt-6 border-t mb-5">
                    <p className="text-black/60">
                        <span className="text-4xl text-black/70 font-bold">$35 </span>
                    </p>
                    <button onClick={() => handleNotificationOpen("formation + certificat")} className="w-full rounded py-2 text-center text-white font-bold bg-primary my-5">{translate(language, 'buy500Credit')}</button>
                </div>
            </div>
            <div className="border py-3 px-5 rounded-lg w-80">
                <p className="text-xl font-semibold text-black/80 my-2 h-14">PACK FORMATION</p>
                <p className="text-[14px] h-10 text-black/80">{translate(language, 'severalsPackage')}</p>
                <div className="h-60 flex justify-center items-center">
                    <img src="/image/BuyPack.avif" alt="formation" className="w-[60%] h-40 object-cover max-lg:w-[40%]" />
                </div>
                <div className="h-32 pt-6 border-t mb-5">
                    <p className="text-black/60">
                        <span className="text-4xl text-black/70 font-bold">$65 </span>
                    </p>
                    <button onClick={() => handleNotificationOpen("pack formation")} className="w-full rounded py-2 text-center text-white font-bold my-5 bg-primary">{translate(language, 'buy700Credit')}</button>
                </div>
            </div>
            {showModal && (
                <Modal
                    isOpen={showModal}
                    onRequestClose={handleNotificationClose}
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                >
                    <div
                        ref={modalRef}
                        className={`bg-white relative flex rounded-lg p-6 shadow-lg w-96 transform transition-all duration-300 ease-in-out ${image ? "w-[50vw] max-lg:w-[70vw]" : ""} max-lg:flex-col`}
                    >
                        <div className="p-7 rounded-full bg-primary absolute -top-9 left-[40%]">
                            <img src="/Addpoint.jpeg" alt="" className="rounded-full w-6 h-6" />
                        </div>
                        <div className="flex w-full mt-5 flex-col items-start rounded-md bg-default-background shadow-sm">
                            <p className="text-lg text-center text-[#1e293b] font-bold w-full my-3">
                                Paiment pour {type}
                            </p>
                            <p className="flex items-center justify-center w-full">
                                <span>Envoyez $35 vers </span>
                                <div className="text-red-500 ml-3">
                                    <p>+261348696520</p>
                                    <p>+261338696520</p>
                                    <p>+261328696520</p>
                                </div>
                            </p>
                            {image && (
                                <div className="ml-4 lg:hidden h-52 w-full flex justify-center items-center">
                                    <img src={image} alt="Uploaded" className="max-w-[20vw] max-h-[50vh] rounded-lg max-md:w-full max-md:object-contain" />
                                </div>
                            )}
                            <div className="flex flex-col items-center gap-2">
                                <span className="text-body font-body text-center py-5 text-[#1e293b]">
                                    Envoyez-nous un capture d'ecran une fois la transaction fait
                                </span>
                            </div>
                            {!image ? (
                                <div className="flex justify-around w-full">
                                    <input type="file" id="EnvoyeCapture" className="hidden" onChange={handleImageUpload} accept="image/*" />
                                    <label
                                        htmlFor="EnvoyeCapture"
                                        className="text-nowrap w-40 bg-primary hover:bg-opacity-90 cursor-pointer rounded text-center text-white px-4 py-2 flex items-center font-bold"
                                    >
                                        Envoyer capture
                                    </label>
                                    <button
                                        onClick={handleNotificationClose}
                                        className="w-40 text-center cursor-pointer px-4 py-2 font-bold border border-primary rounded text-[#020617] text-opacity-90 border-opacity-90"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            ) : (
                                <div className="flex justify-around w-full max-md:flex-col max-md:gap-y-5">
                                    <input type="file" id="EnvoyeCapture" className="hidden" onChange={handleImageUpload} accept="image/*" />
                                    <label
                                        htmlFor="EnvoyeCapture"
                                        className="text-nowrap w-40 border border-primary hover:bg-opacity-90 cursor-pointer rounded text-center text-[#020617] px-4 py-2 flex items-center font-bold max-md:w-full max-md:pl-14"
                                    >
                                        Changer l'image
                                    </label>
                                    <button
                                        onClick={addPointClicked}
                                        disabled={image === null}
                                        className="w-40 text-center cursor-pointer px-4 py-2 font-bold bg-primary rounded text-white text-opacity-90 border-opacity-90 max-md:w-full"
                                    >
                                        Envoyer
                                    </button>
                                </div>
                            )}
                        </div>
                        {image && (
                            <div className="ml-4 max-lg:hidden flex justify-center items-center">
                                <img src={image} alt="Uploaded" className="max-w-[20vw] max-h-[50vh] rounded-lg" />
                            </div>
                        )}
                    </div>
                </Modal>
            )}
            <ToastContainer />
        </div>
    )
}