import axios from 'axios';

// export const baseURL = 'http://192.168.1.139:8001/api'
// export const baseUrlImage = 'http://192.168.1.139:8001'
export const baseURL = "https://hayplus-api.zoe.mg/api";
export const baseUrlImage = "https://hayplus-api.zoe.mg";
export const apiOllama = "http://192.168.1.183:3001";

const api = axios.create({
  baseURL: `${baseURL}`,
});

export default api
