import React, { useState } from "react";
import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import { LoginInput } from "../../Components/Input/LoginInput";
import { LoginPswrdInput } from "../../Components/Input/LoginPswrdInput";
import { Link, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store/User/user";
import RadioInput from "../../Components/Input/RadioBox";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import translate from "../../store/Translation/Langue";
import { UseLanguage } from "../../store/User/language";
import { NIVEAU, TYPE } from "../../config/constant";
import { OSSelect } from "../../Components/Input/OSSelect";

export const Inscription = () => {
    const [name, setname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setpassword] = useState("")
    const [confiramtionpassword, setConfirmationpassword] = useState("")
    const [type_utilisateur, settype_utilisateur] = useState("etudiant")
    const [error, setError] = useState("");
    const [errorsEmail, setErrorsEmail] = useState();
    const [errorsName, setErrorsName] = useState();
    const register = useAppStore.use.register()
    const navigate = useNavigate()
    const { language } = UseLanguage()
    const [selectedNiveau, setSelectedNiveau] = useState("");


    const handleOptionChange = (e) => {
        settype_utilisateur(e.target.value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!name) {
            return setErrorsName("Veuillez entrer votre nom.")
        }
        else {
            setErrorsName("")
        }

        if (email) {
            if (!/\S+@\S+\.\S+/.test(email)) {
                return setErrorsEmail("Veuillez entrer une adresse e-mail valide.")
            }
            else {
                setErrorsEmail("")
            }
        }
        else {
            return setErrorsEmail("Veuillez entrer votre adresse e-mail.")
        }

        if (password.length < 8) {
            setError("Le mot de passe doit contenir au moins 8 caractères.");
        } else {
            setError("");
            if (confiramtionpassword === password) {
                const result = await register(name, email, password, type_utilisateur, selectedNiveau)
                if (result.success) {
                    toast.success("Inscription terminé!", {
                        onClose: () => {
                            navigate('/authentification')
                        }
                    });
                }
            }
            else {
                setError("Le mot de passe doit etre la meme.")
            }
        }
    }


    return (
        <div className="relative flex items-center justify-center h-screen">
            <div
                className="absolute inset-0 bg-cover bg-center w-full min-h-screen"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="min-h-screen w-screen" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" preserveAspectRatio="none" viewBox="0 0 1440 560">
                    <g mask="url(&quot;#SvgjsMask3329&quot;)" fill="none">
                        <rect width="1440" height="560" x="0" y="0" fill="url(&quot;#SvgjsRadialGradient3330&quot;)"></rect>
                        <path d="M48.72 648.69C226.51 648.52 390.87 563.77 754.47 561.16 1118.07 558.55 1271.96 302.2 1460.22 297.96" stroke="rgba(104, 134, 218, 0.34)" stroke-width="2"></path>
                        <path d="M455.43 606.94C554.17 597.22 516.74 395.83 785.94 387.21 1055.13 378.59 1274.2 187.83 1446.95 185.61" stroke="rgba(104, 134, 218, 0.34)" stroke-width="2"></path>
                        <path d="M390.97 599.92C558.41 598.63 698.29 440 1039.99 434.5 1381.68 429 1513.98 176.24 1689 171.3" stroke="rgba(104, 134, 218, 0.34)" stroke-width="2"></path>
                        <path d="M372.34 629.44C516.68 567.65 446.03 122.27 734.63 116.43 1023.24 110.59 1275.47 239.19 1459.22 239.63" stroke="rgba(104, 134, 218, 0.34)" stroke-width="2"></path>
                        <path d="M539.81 583.04C696.76 575.32 790.49 315.28 1101.85 301.58 1413.21 287.88 1509.44 50.02 1663.89 43.98" stroke="rgba(104, 134, 218, 0.34)" stroke-width="2"></path>
                    </g>
                    <defs>
                        <mask id="SvgjsMask3329">
                            <rect width="1440" height="560" fill="#ffffff"></rect>
                        </mask>
                        <radialGradient cx="100%" cy="50%" r="1466.97" gradientUnits="userSpaceOnUse" id="SvgjsRadialGradient3330">
                            <stop stop-color="rgba(142, 192, 255, 1)" offset="0"></stop>
                            <stop stop-color="rgba(144, 255, 181, 1)" offset="0.06"></stop>
                            <stop stop-color="rgba(240, 255, 240, 1)" offset="0.18"></stop>
                            <stop stop-color="rgba(244, 255, 242, 1)" offset="0.33"></stop>
                            <stop stop-color="rgba(255, 255, 255, 1)" offset="0.58"></stop>
                        </radialGradient>
                    </defs>
                </svg>
            </div>
            <div className="relative max-md:hidden w-[65%] text-black/80 h-72 flex pl-14 rounded-lg flex-col justify-center items-start bg-gray-100/20 shadow-cardShadow max-lg:w-[80%] max-lg:pl-10">
                <p className="text-3xl font-semibold w-80">{translate(language, "WantVisited")}</p>
                <Link to="/" className="border border-secondary py-1 px-7 mt-5 shadow-blue-500/20 rounded">{translate(language, "clickHere")}</Link>
            </div>
            <div className="absolute max-sm:static max-sm:right-0 z-30 right-64 py-5 px-7 h-auto bg-white w-auto shadow-2xl rounded max-lg:w-80 max-lg:right-36">
                <p className="max-md:text-xl w-full text-center text-gray-800 text-2xl font-semibold">{translate(language, "createAccount")} !</p>
                <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col mt-5 items-center justify-center">
                    <div className="w-full">
                        <LoginInput icon={faUser} id="nom" placeholder={translate(language, "name")} state={((e) => setname(e.target.value))} error={errorsName} />
                        <LoginInput icon={faEnvelope} id="email" placeholder="Email" state={((e) => setEmail(e.target.value))} error={errorsEmail} />
                        <LoginPswrdInput id="Mot_de_passe" error={error} placeholder={translate(language, "password")} state={((e) => setpassword(e.target.value))} />
                        <LoginPswrdInput id="confirmMdp" error={error} placeholder={translate(language, "ConfirmaPswd")} state={((e) => setConfirmationpassword(e.target.value))} />
                        <div className="flex justify-center my-5" >
                            <RadioInput
                                value="etudiant"
                                name="usertype_utilisateur"
                                onChange={handleOptionChange}
                                label={translate(language, "student")}
                                checked={type_utilisateur === TYPE.etudiant}
                            />
                            <RadioInput
                                value="enseignant"
                                name="usertype_utilisateur"
                                onChange={handleOptionChange}
                                label={translate(language, "teacher")}
                                checked={type_utilisateur === TYPE.enseigant}
                            />
                        </div>
                        {type_utilisateur === TYPE.etudiant && (
                            <>
                                <p className="text-gray-500">Niveau :</p>
                                <OSSelect id="niveau" items={NIVEAU} onChange={((e) => setSelectedNiveau(e.target.value))} />
                                <br />
                            </>
                        )}
                    </div>
                    <button type_utilisateur="submit" className="w-4/5 py-2 px-4 text-sm font-semibold rounded text-white bg-thirdly hover:bg-green-800 focus:outline-none">
                        {translate(language, "createAccount")}
                    </button>
                </form>
                <div className="lg:hidden flex mt-5 justify-between text-black/70 text-sm">
                    <Link to="/authentification">Se connecter</Link>
                    <p>Mot de passe oublier ?</p>
                </div>
                <p className="text-sm max-lg:hidden mt-6 text-center text-black/80">
                    {translate(language, "HaveAccount")}
                    <Link to="/authentification" className="text-thirdly font-semibold hover:underline ml-1">{translate(language, "SingIn")}</Link>
                </p>
            </div>
            <ToastContainer />
        </div>
    )
}