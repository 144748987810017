import React, { useEffect, useState } from "react";
import { ExamenNavigation } from "../../Components/NavBar/ExamenNavigation";
import { ExamenCardEtudiant } from "../../Components/Cards/Examen/ExamenCardEtudiant";
import { ExamenCard } from "../../Components/Cards/Examen/ExamenCard";
import { useAppStore } from "../../store/User/user";
import { PasserExamenCard } from "../../Components/Cards/Etudiant/PasserExamenCard";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { AucunElement } from "../../Components/Cards/AucunElementCard";
import { ExamenCardAdministrateur } from "../../Components/Cards/Admin/ExamenCard";

export const Examen = () => {
    const [matiere, setMatiere] = useState([])
    const type = useAppStore.use.type()
    const [isLoading, setIsLoading] = useState(false);

    // const fetchMatiere = async () => {
    //     try {
    //         const response = await axios.get(`http://91.134.91.43:8000/api/matieres`)
    //         setMatiere((prev) => {
    //             return response.data
    //         })
    //         setIsLoading(false)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    useEffect(() => {
        // fetchMatiere()
    }, [])

    const [item, setItems] = useState(0)

    const renderItem = (items) => {
        switch (items) {
            case 0:
                return (
                    <ExamenCardEtudiant />
                )
            case 1:
                return (
                    <PasserExamenCard items={matiere} />
                )
            default:
                return null
        }
    }

    const changeItem = () => {
        setItems(1)
    }

    if (isLoading) { return <LoadingScreen /> }

    return (
        <div className="mt-20 w-full flex flex-col items-center min-h-screen">
            {
                type === "administrateur" ? (
                    matiere.length !== 0 ? (
                        <ExamenCardAdministrateur />
                    ) : (
                        <div className="w-1/3 ml-[15vw] mt-28">
                            <AucunElement item={"examen"} />
                        </div>
                    )
                ) : (
                    type === "enseignant" ? (
                        <div className="w-full flex flex-col items-center">
                            <ExamenCard isButton />
                            <div className="w-1/3 ml-[15vw]">
                                <AucunElement item={"examen"} />
                            </div>
                        </div>
                    ) : (
                        <div className="flex w-5/6 justify-center">
                            <ExamenNavigation ItemChanged={changeItem} />
                            <div className="w-1/2 h-full">{renderItem(item)} </div>
                        </div>
                    )
                )
            }
        </div>
    );
};
