import React from "react";
import { CheckBoxInput } from "../Input/CheckBoxInput";
import { useSearchParams } from "react-router-dom";

export const SelectionType = ({ categorie, isAlign }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleOptionChange = (e) => {
        const value = e.target.value;
        const selectedOptions = searchParams.get("value") ? searchParams.get("value").split(",") : [];
        const newSelectedOptions = selectedOptions.includes(value)
            ? selectedOptions.filter(i => i !== value)
            : [...selectedOptions, value];

        setSearchParams({ value: newSelectedOptions.join(",") });
    };

    return (
        <div className={`w-full h-full flex flex-col items-center justify-center`}>
            <div className={`${isAlign ? "flex flex-wrap w-[85%] justify-around py-3 rounded shadow-cardShadow mb-7 z-10 bg-white" : "w-full"}`}>
                {categorie?.map((item, index) => (
                    <CheckBoxInput
                        key={index}
                        value={item.id}
                        name="categorie"
                        onChange={handleOptionChange}
                        label={item.nom}
                        checked={searchParams.get("value") ? searchParams.get("value").split(",").includes(item.id.toString()) : false}
                    />
                ))}
            </div>
        </div>
    );
};



// const handleOptionChange = (e) => {
//     const value = e.target.value;
//     const selectedOptions = searchParams.get("value") ? JSON.parse(searchParams.get("value")) : []
//     const newSelectedOptions = selectedOptions.includes(value)
//         ? [...selectedOptions].filter(i => i != value)
//         : [...selectedOptions, value]
//     setSearchParams({ value: JSON.stringify(newSelectedOptions) });
// };