import React, { useEffect, useRef, useState } from "react";
import api from "../../../store/Api/Intercepteur";
import Modal from "react-modal";
import DeleteCard from "../../DeleteConfirmation/DeleteCard";

export const UtilisateurCard = ({ items, onRefresh, sendResponseError, sendResponseSuccess }) => {
    const modalSuppressionRef = useRef(null);
    const [showSuppressionModal, setShowSuppressionModal] = useState(false)
    const [Id, setId] = useState(null)

    const handleDeleteClicked = (id) => {
        setShowSuppressionModal(true);
        setId(id)
    }

    const confirmDelete = async () => {
        try {
            await api.delete(`/users/${Id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
            setShowSuppressionModal(false)
            sendResponseSuccess("Utilisateur supprimé avec succès!")
            onRefresh();
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setShowSuppressionModal(false)
                return sendResponseError("Erreur lors de la suppression de l'utilisateur! Veillez réessayer!")
            }
            console.log(error)
        }
    }

    const cancelDelete = async () => {
        setShowSuppressionModal(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalSuppressionRef.current && !modalSuppressionRef.current.contains(event.target)) {
                setShowSuppressionModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div className="w-full px-5">
            <table className="table table-auto table-border w-full" data-datatable-table="true">
                <thead>
                    <tr className="text-blue font-bold h-10 border-b">
                        <th className="text-left pl-5">
                            <span className="sort">
                                <span className="sort-label">
                                    Nom
                                </span>
                                <span className="sort-icon">
                                </span>
                            </span>
                        </th>
                        <th className="text-left">
                            <span className="sort">
                                <span className="sort-label">
                                    E-mail
                                </span>
                                <span className="sort-icon">
                                </span>
                            </span>
                        </th>
                        <th className="text-left">
                            <span className="sort">
                                <span className="sort-label">
                                    Type
                                </span>
                                <span className="sort-icon">
                                </span>
                            </span>
                        </th>
                        <th className="w-32">
                            <span className="sort">
                                <span className="sort-label">
                                    Action
                                </span>
                                <span className="sort-icon">
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items?.map((item, index) => (
                        <tr key={index} className={`border-b h-10 ${index % 2 !== 0 ? '' : 'bg-gray-100'}`}>
                            <td className="drop-shadow-2xl font-semibold text-black/80 pl-5">{item.name}</td>
                            <td className="drop-shadow-2xl font-semibold text-black/80">{item.email}</td>
                            <td className="drop-shadow-2xl font-semibold text-black/80">{item.type_utilisateur}</td>
                            <td className='text-center'>
                                <button
                                    className="text-green-700/80"
                                    onClick={() => handleDeleteClicked(item.id)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="red" width={16} height={16}>
                                        <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal
                isOpen={showSuppressionModal}
                onRequestClose={() => setShowSuppressionModal(false)}
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                overlayclassName="fixed inset-0 bg-black bg-opacity-50"
                shouldCloseOnOverlayClick={true}
            >
                <div ref={modalSuppressionRef} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-20 ml-[20vw]">
                    <DeleteCard onCancel={cancelDelete} onConfirm={confirmDelete} />
                </div>
            </Modal>
        </div>
    );
};
