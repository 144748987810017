import React, { useEffect, useState } from "react";
import { CoursCardAdmin } from "../../Components/Cards/Admin/CoursCard";
import { useAppStore } from "../../store/User/user";
import api from "../../store/Api/Intercepteur";
import { SelectionType } from "../../Components/Filtre/SelectionType";
import { SelectionCoursSuivi } from "../../Components/Filtre/FiltreCoursSuivie";
import { CoursCard } from "../../Components/Cards/Etudiant/CoursCard";
import translate from "../../store/Translation/Langue";
import { UseLanguage } from "../../store/User/language";
import { useSearchParams } from "react-router-dom";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { AucunElement } from "../../Components/Cards/AucunElementCard";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FiltreParPack } from "../../Components/Filtre/FiltreParPack";

export const Cours = () => {
    const language = UseLanguage.use.language();
    const [searchParams, setSearchParams] = useSearchParams();
    const [cour, setCours] = useState([]);
    const [filteredCours, setFilteredCours] = useState([]);
    const type = useAppStore.use.type();
    const [categorie, setCategorie] = useState([]);
    // const [searchQuery, setSearchQuery] = useState(searchParams.get("value") ? JSON.parse(searchParams.get("value")) : []);
    const [searchQuery, setSearchQuery] = useState(searchParams.get("value") ? searchParams.get("value").split(",") : []);
    const [packQuery, setPackQuery] = useState(searchParams.get("pack") ? searchParams.get("pack").split(",") : []);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const fetchCours = async () => {
        try {
            const response = await api.get(`/cours/prof`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            await setCours(() => {
                return response.data
            });
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    const triggerRefresh = () => {
        setIsRefresh(!isRefresh);
    };

    const fetchCategorie = async () => {
        try {
            if (type === "etudiant") {
                const response = await api.get(`/categories`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setCategorie(() => {
                    console.log(response.data)
                    return response.data
                });
            } else {
                const response = await api.get(`/visiteurs`);
                setCategorie(() => {
                    console.log(response.data)
                    return response.data
                });
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (type === "enseignant") {
            fetchCours();
        }
        else {
            fetchCategorie();
        }
    }, [isRefresh, type]);

    useEffect(() => {
        if (searchQuery.length !== 0) {
            const selectedCat = categorie?.filter((cat) => searchQuery.includes(cat.id.toString()));
            setFilteredCours(selectedCat.flatMap(cat => {
                return cat.cours.map((c) => {
                    if (type === "etudiant") {
                        if (c.user_cours.length !== 0) {
                            return {
                                ...c,
                                categorie: cat.nom,
                                isSuivi: true,
                                enseignant: c.user.name
                            }
                        }
                    }
                    return {
                        ...c,
                        categorie: cat.nom,
                        isSuivi: false,
                        enseignant: c.user.name
                    }
                })
            }));
        } else {
            setFilteredCours(categorie?.flatMap(cat => {
                return cat.cours.map((c) => {
                    if (type === "etudiant") {
                        if (c.user_cours.length !== 0) {
                            return {
                                ...c,
                                categorie: cat.nom,
                                isSuivi: true,
                                enseignant: c.user.name
                            }
                        }
                    }
                    return {
                        ...c,
                        categorie: cat.nom,
                        isSuivi: false,
                        enseignant: c.user.name
                    }
                })
            }));
        }
    }, [searchQuery, categorie, cour, type]);

    useEffect(() => {
        if (packQuery) {
            const selectedCat = categorie?.flatMap(cat => {
                return cat.cours
                    .filter(c => c.id_pack === parseInt(packQuery))
                    .map(c => {
                        if (type === "etudiant") {
                            if (c.user_cours.length !== 0) {
                                return {
                                    ...c,
                                    categorie: cat.nom,
                                    isSuivi: true,
                                    enseignant: c.user.name
                                };
                            }
                        }
                        return {
                            ...c,
                            categorie: cat.nom,
                            isSuivi: false,
                            enseignant: c.user.name
                        };
                    });
            });

            setFilteredCours(selectedCat);
        }
    }, [searchParams, categorie, cour, type]);


    // useEffect(() => {
    //     setSearchQuery(searchParams.get("value") ? JSON.parse(searchParams.get("value")) : []);
    // }, [searchParams]);

    useEffect(() => {
        const urlValues = searchParams.get("value") ? searchParams.get("value").split(",") : [];
        setSearchQuery(urlValues);
    }, [searchParams]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    const toastShowSuccess = (message) => {
        toast.success(message)
    }

    const toastShowError = (message) => {
        toast.error(message)
    }

    return (
        <div className="flex flex-col w-full justify-center items-center mb-40 mt-14">
            <div
                className="absolute max-sm:hidden bg-repeat inset-0 bg-contain bg-fixed bg-center w-full h-full"
                style={{ backgroundSize: "cover" }}
            >
                <div className="w-full h-full fixed">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsvgjs="http://svgjs.dev/svgjs" className="min-h-screen w-screen" preserveAspectRatio="none" viewBox="0 0 1440 560">
                        <g mask="url(&quot;#SvgjsMask1297&quot;)" fill="none">
                            <rect width="1440" height="560" x="0" y="0" fill="url(&quot;#SvgjsRadialGradient1298&quot;)"></rect>
                            <path d="M -945.3550179832923,276 C -849.36,316.6 -657.36,487 -465.35501798329227,479 C -273.36,471 -177.36,245.4 14.64498201670773,236 C 206.64,226.6 302.64,465.2 494.64498201670773,432 C 686.64,398.8 785.57,57 974.6449820167077,70 C 1163.72,83 1346.93,411.6 1440,497" stroke="rgba(51, 121, 194, 0.58)" strokeWidth="2"></path>
                            <path d="M -564.7707203737228,131 C -468.77,189.8 -276.77,401.2 -84.77072037372281,425 C 107.23,448.8 203.23,265.4 395.22927962627716,250 C 587.23,234.6 683.23,346.8 875.2292796262772,348 C 1067.23,349.2 1242.28,264.4 1355.2292796262773,256 C 1468.18,247.6 1423.05,296 1440,306" stroke="rgba(51, 121, 194, 0.58)" strokeWidth="2"></path>
                        </g>
                        <defs>
                            <mask id="SvgjsMask1297">
                                <rect width="1440" height="560" fill="#ffffff"></rect>
                            </mask>
                            <radialGradient cx="50%" cy="50%" r="772.53" gradientUnits="userSpaceOnUse" id="SvgjsRadialGradient1298">
                                <stop stopColor="rgba(230, 255, 232, 1)" offset="0"></stop>
                                <stop stopColor="rgba(255, 255, 255, 1)" offset="1"></stop>
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            {
                type === "administrateur" ? (
                    <div className="ml-40 relative flex justify-between items-center w-5/6 h-full min-h-screen font-medium max-xl:w-full max-sm:flex-col">
                        <div className="fixed pt-16 lg:ml-10 w-72 max-sm:hidden overflow-y-auto top-5 bg-white p-4 h-[100vh] shadow-shadows rounded-lg">
                            <div className="mb-2">
                                <p className="text-lg font-bold text-blue-950">Categorie</p>
                                <div className="h-0.5 w-12 bg-primary"></div>
                            </div>
                            <div>
                                <SelectionType categorie={categorie} setSelectedCategorie={setSearchQuery} />
                            </div>
                        </div>
                        <div className="sm:hidden">
                            <SelectionType categorie={categorie} setSelectedCategorie={setSearchQuery} isAlign />
                        </div>
                        <div className="grid grid-cols-2 ml-[340px] mt-20 w-full max-lg:ml-60 max-lg:flex max-lg:justify-around flex-wrap max-sm:ml-0">
                            {filteredCours.length !== 0 ? filteredCours.map((item, index) => (
                                <CoursCard key={index} items={item} isSuivi={item.isSuivi} onRefresh={triggerRefresh} />
                            )) : (
                                <div className="w-full ml-40">
                                    <AucunElement item={"cours"} />
                                </div>
                            )
                            }
                        </div>
                    </div>
                ) : (
                    type === "enseignant" ? (
                        <div className="relative ml-60 w-3/4">
                            <CoursCardAdmin isButton={true} />
                            {cour.length !== 0 ? (
                                <CoursCardAdmin items={cour} onRefresh={triggerRefresh} sendResponseSuccess={toastShowSuccess} sendResponseError={toastShowError} />
                            ) : (
                                <div className="w-1/2 ml-[15vw]">
                                    <AucunElement item={"cours"} />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="relative flex justify-between items-center p-5 pl-14 w-5/6 h-full min-h-screen font-medium max-xl:w-full max-sm:flex-col max-sm:pl-4">
                            <div className="fixed pt-16 lg:ml-10 w-72 max-sm:hidden overflow-y-auto top-5 bg-white p-4 h-[100vh] shadow-shadows rounded-lg">
                                <div className="mb-2">
                                    <p className="text-lg font-bold text-blue-950">Categorie</p>
                                    <div className="h-0.5 w-12 bg-primary"></div>
                                </div>
                                <div>
                                    <SelectionType categorie={categorie} setSelectedCategorie={setSearchQuery} />
                                </div>
                                <div className="mb-2">
                                    <p className="text-lg font-bold text-blue-950">Pack</p>
                                    <div className="h-0.5 w-12 bg-primary"></div>
                                </div>
                                <div>
                                    <FiltreParPack categorie={categorie} />
                                </div>
                                {type === "etudiant" && (
                                    <div className="mt-5 mb-2">
                                        <p className="text-lg font-bold text-blue-950">{translate(language, "yaCourse")}</p>
                                        <div className="h-0.5 w-12 bg-primary"></div>
                                        {cour.map((item, index) => (
                                            <SelectionCoursSuivi key={index} chapitre={item.nom} />
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="sm:hidden">
                                <SelectionType categorie={categorie} setSelectedCategorie={setSearchQuery} isAlign />
                            </div>
                            <div className="grid grid-cols-2 ml-[340px] w-full max-lg:ml-60 max-lg:flex max-lg:justify-around flex-wrap max-sm:ml-0 overflow-y-auto">
                                {filteredCours.length !== 0 ? filteredCours.map((item, index) => (
                                    <CoursCard key={index} items={item} isSuivi={item.isSuivi} />
                                )) : (
                                    <div className="w-full ml-40">
                                        <AucunElement item={"cours"} />
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    ))}
            <ToastContainer />
        </div>
    );
};