import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";

export const LoginPswrdInput = ({
    placeholder,
    id,
    error,
    state,
    defaultValue,
}) => {
    const [eye, setEye] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue || "");

    const togglePasswordVisibility = () => {
        setEye(!eye);
        setShowPassword(!showPassword);
    };

    const handleChange = (e) => {
        setInputValue(e.target.value);
        state({ target: { name: id, value: e.target.value } });
    };

    return (
        <div className="relative w-full min-w-[200px] mb-4">
            <input
                className={`peer w-full h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-gray-200 placeholder-shown:border-t-gray-200 border border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-gray-200 focus:border-primary ${error ? "border-red-500" : ""}`}
                type={showPassword ? "text" : "password"}
                id={id}
                value={inputValue}
                onChange={handleChange}
                placeholder=""
                name='password-hay-plus'
            />
            <label
                className={`flex w-full h-full text-black/60 select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t before:border-l before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t after:border-r  after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-primary before:border-blue-gray-200 peer-focus:before:!border-primary after:border-blue-gray-200 peer-focus:after:!border-primary ${error ? "before:border-red-500 after:border-red-500 text-red-500" : ""}`}>
                {placeholder}
            </label>
            <FontAwesomeIcon
                icon={eye ? faUnlock : faLock}
                className="absolute top-3 right-3 text-gray-500 cursor-pointer"
                onClick={togglePasswordVisibility}
            />
            <p className="text-xs text-red-500 py-1">{error}</p>
        </div>
    );
};
