import React, { useEffect, useState } from "react";
import { ExamenCard } from "../../../Components/Cards/Examen/ExamenCard";
import { ExerciceCard } from "../../../Components/Cards/Exercice/ExerciceCard";
import { AucunElement } from "../../../Components/Cards/AucunElementCard";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";
import api from "../../../store/Api/Intercepteur";
import { useAppStore } from "../../../store/User/user";

export const Exercice = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [cours, setCours] = useState([])
    const [isRefresh, setIsRefresh] = useState(false)

    const fetchExercice = async () => {
        setCours([])
        try {
            const response = await api.get(`/cours`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
            setCours((prev) => {
                console.log(response.data)
                return response.data
            })
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const trigerRefresh = () => { setIsRefresh(!isRefresh) }

    useEffect(() => { fetchExercice() }, [isRefresh])

    if (isLoading) return <LoadingScreen />

    return (
        <div className="mt-20 w-full flex flex-col items-center h-full">
            <div className="w-full flex flex-col items-center justify-center pl-64">
                <ExamenCard isButton />
                <div className="w-full min-h-screen">
                    {cours.length !== 0 ? (
                        <ExerciceCard course={cours} onRefresh={trigerRefresh} />
                    ) : (
                        <div className="w-1/2 ml-[15vw]">
                            <AucunElement item={"exercice"} />
                        </div>
                    )
                    }
                </div>
            </div>
        </div >
    );
};
