export const TYPE = {
    enseigant: 'enseignant',
    etudiant: 'etudiant',
    admin: 'administrateur',
    visiteur: ''
}

export const NIVEAU = [
    {
        id: "3em",
        name: '3em'
    },
    {
        id: "Seconde",
        name: 'Seconde'
    },
    {
        id: "Première",
        name: 'Première'
    },
    {
        id: "Terminale",
        name: 'Terminale'
    },
    {
        id: "L1",
        name: 'L1'
    },
    {
        id: "L2",
        name: 'L2'
    },
    {
        id: "L3",
        name: 'L3'
    },
    {
        id: "M1",
        name: 'M1'
    },
    {
        id: "M2",
        name: 'M2'
    },
]