export const translations = {
    fr: {
        //Language
        fr: "Français",
        en: "Anglais",
        esp: "Espagnol",
        ital: "Italien",
        mg: 'Malgache',
        //Loading
        loading: "Chargement",
        //Flag
        flag: "/Flag/french.png",
        //NavBar
        home: "Accueil",
        courses: "Cours",
        contact: "Contact",
        register: "S'inscrire",
        login: "Se connecter",
        exam: "Examen",
        profile: "Profil",
        certificat: "Certificat",
        addPoint: "Acheter credit",
        exercise: "Exercice",
        about: "A propos",
        user: "Utilisateur",
        //USER
        students: "étudiant",
        employee: "employé",
        business_man: "homme d'affaire",
        colaborator: "collaborateur",
        //Home
        welcome: "Bienvenue cher ",
        titleAfterWelcome: "Bienvenue sur notre plateforme de formation et de remise à niveau dédiée aux employés et aux professionnels ambitieux.",
        search: "Rechercher cours",
        hi: "Bonjour",
        youHave: "Vous avez",
        newEvent: "nouveaux événements",
        viewAllEvent: "Voir tous les événements",
        dashboard: "Tableau de bord",
        chargeAccount: "Charger le compte",
        titreCarousel1: "Suivi Régulier du Cours",
        descriptioCarousel1: "Gardez facilement une trace de votre progression grâce au suivi régulier de nos cours.Restez au top de vos objectifs d'apprentissage avec des rappels et des mises à jour réguliers.",
        titreCarousel2: "Contenu de Cours Diversifié",
        descriptioCarousel2: "Nos cours offrent une riche combinaison de vidéos, de documents textuels et d'images pour améliorer votre expérience d'apprentissage. Engagez-vous avec du contenu sous différents formats pour une compréhension complète..",
        titreCarousel3: "Certification à la Clé",
        descriptioCarousel3: "Obtenez une certification reconnue à la fin de votre formation. Mettez en avant vos nouvelles compétences et connaissances auprès des employeurs potentiels.",
        //prof homecard 
        balance: "Solde du compte",
        purchased: "Cours acheté",
        meeting: "réunion",
        yourStudent: "Vos étudiants",
        //Cours
        yaCourse: "Vos cours",
        interestingCourse: "Cours interressants",
        in: "Dans",
        hour: "heures",
        followed: "Suivi",
        //Details cours
        createdBy: "Créer par",
        yllLearn: "Qu'allez vous Apprendre ? ",
        aboutCourse: "À propos de cet cours",
        addCard: "Ajouter aux cartes",
        buy: "Acheter maitennant !",
        payInstallment: "Payer en plusieurs fois",
        lesson: 'Leçon',
        //Selection Part
        finished: 'Complète',
        ongoing: "En cours",
        PassExam: "Passer l'examen",
        //Profile 
        following: "",
        follower: "",
        //Footer
        AboutUs: "À propos de nous",
        privacyPolicy: "Politique de confidentialité",
        condition: "Conditions d'utilisation",
        plan: "Plan du site",
        droitDauteur: "Tous droits réservés",
        explore: "Explorez, grandissez, apprenez plus",
        receiveThe: "Recevoir le",
        ourDomain: "Nos domaines",
        ourAdress: "Notre adresse",
        sendEmail: "Envoyer l'email",
        sendFeedBaak: 'Envoyer un feedback',
        //Contact
        infoContact: "Information de contact",
        doyouHave: 'Avez-vous des suggestions ou des idées d’améliorations ?',
        contenu: "Contenu",
        description: "Description",
        title: 'Titre',
        yaOpinionMatter: 'Votre opinion compte pour nous !',
        MaybeLater: "Peut-être plus tard",
        //Input: 
        name: "Nom",
        phone: "Téléphone",
        subject: "Sujet",
        sendMessage: "Envoyer le message",
        message: "Message",
        // questionContact: "Avez-vous une grande idée ou une marque à développer et besoin d'aide ?",
        //Auth
        welcomeAuth: "Bienvenue",
        WantVisited: "Voulez-vous visiter notre plateforme ?",
        clickHere: "Cliquez ici",
        EmailAdress: "Adresse Email",
        password: "Mot de passe",
        ForgotPswd: "Mot de passe oublié ?",
        SingUp: "S'incrire",
        NoAccount: "Vous n'avez pas encore un compte ?",
        //Inscription
        createAccount: "Créer un compte",
        ConfirmaPswd: "Confirmer le mot de passe",
        student: "Etudiant",
        teacher: "Enseignant",
        HaveAccount: " Vous avez déjà un compte ?",
        SingIn: "Se connecter",
        //Certificat
        certificate: "Certificat",
        certificatCode: "Entrez votre code de certificat",
        AuthenticCertificate: ' Vérification Authentique des Certificats',
        gettingCertificateDescription: `Le certificats attestent que vous avez acquis certaines connaissances
                    ou compétences après avoir suivi un cours ou une formation.Notre
                    institution a mis en place un système de vérification de certificat en
                    ligne pour garantir l'authenticité et l'intégrité des certificats que
                    nous délivrons.Cette démarche renforce la confiance dans nos
                    certifications, facilite la vérification pour les employeurs, et lutte
                    contre les fraudes.Nous utilisons des technologies de pointe pour
                    garantir la sécurité et l'intégrité des données, et nous sommes fiers
                    de garantir à nos partenaires une transparence totale dans l'émission
                    de nos certificats.`,
        searchCertificate: "Rechercher",
        //Passer examen
        lookResult: "Jetons un coup d'œil à vos résultats",
        and: "et",
        result: "Resultat",
        returnToCourse: 'Retourner a vos cours',
        //AddPoint
        decriptionFomartion: 'Couvre uniquement les frais de formation',
        decriptionFomartionCertificat: 'Inclut les frais de formation et un certificat à la fin',
        formation: 'Formation',
        severalsPackage: 'Couvre les frais de plusieurs pack',
        buy200Credit: 'Acheter 200 crédits',
        buy500Credit: 'Acheter 500 crédits',
        buy700Credit: 'Acheter 700 crédits',
        aboutHayPlus: ` Hay + est une communauté réunissant à la fois des personnes désireuses de partager
                        leur savoir et celles souhaitant apprendre. Comme le disaient les sages malgaches,
                        "Le savoir est le plus grand des héritages." Que cet héritage soit accessible à tous
                        ceux qui ont soif d'apprendre et de transmettre`,
        //FeedBack
        clientFeedBack: "Feedback des clients",
    },
    en: {
        //Language
        fr: "French",
        en: "English",
        esp: "Spanish",
        ital: "Italian",
        mg: 'Malagasy',
        //Loading
        loading: "loading",
        //Flag
        flag: "/Flag/usa.jpeg",
        //Navbar
        home: "Home",
        courses: "Course",
        contact: "Contact",
        register: "Register",
        login: "Login",
        certificat: "Certificate",
        addPoint: "Buy credit",
        exercise: "Exercise",
        about: "About",
        exam: "Exam",
        profile: "Profile",
        user: "User",
        //USER
        students: "student",
        // teacher: "teacher",
        business_man: "business-man",
        employee: "employe",
        colaborator: "collaborator",
        //Home
        welcome: "Welcome, dear ",
        titleAfterWelcome: "Welcome to our training and upskilling platform dedicated to employees and ambitious professionals.",
        search: "Search courses",
        hi: "Hi",
        youHave: "You have",
        newEvent: "new events",
        viewAllEvent: "View all events",
        dashboard: "Dashboard",
        chargeAccount: "Charge account",
        titreCarousel1: "Regular Course Monitoring",
        descriptioCarousel1: "Keep track of your progress effortlessly with our platform's regular course monitoring. Stay on top of your learning goals with timely reminders and updates.",
        titreCarousel2: "Diverse Course Content",
        descriptioCarousel2: "Our courses offer a rich blend of video lectures, textual materials, and images to enhance your learning experience. Engage with content in multiple formats for a comprehensive understanding.",
        titreCarousel3: "Certification Upon Completion",
        descriptioCarousel3: "Earn a recognized certification upon successful completion of your course. Showcase your new skills and knowledge to potential employers.",
        //prof homecard 
        balance: "Account Balance",
        purchased: "Purchased Courses",
        meeting: "Meetings",
        yourStudent: "Yours Students",
        //Cours
        yaCourse: "Your courses",
        interestingCourse: "Interresting courses",
        in: "In",
        hour: "hours",
        followed: "Followed",
        //Details cours
        createdBy: "Created by",
        yllLearn: "What will you learn ? ",
        aboutCourse: "About this course",
        addCard: "Add to card",
        buy: "Buy now !",
        payInstallment: "Pay in installments",
        lesson: "Lesson",
        //Selection Part
        finished: 'Finished',
        ongoing: "Ongoing",
        PassExam: "Pass the exam",
        //Footer
        AboutUs: "About us",
        privacyPolicy: "Privacy Policy",
        condition: "Term  of Use",
        plan: "Plan of the site",
        droitDauteur: "All rights reserved",
        explore: "Explore, grow, learn more",
        receiveThe: "Receive the",
        ourDomain: "Our domain",
        ourAdress: "Our adress",
        sendEmail: "Send Email",
        sendFeedBaak: 'Send feedback',
        //Contact
        infoContact: "Contact information",
        doyouHave: "Do you have any suggestions or ideas for improvements?",
        contenu: "Content",
        description: 'Description',
        title: 'Title',
        yaOpinionMatter: 'Your opinion matters to us !',
        MaybeLater: "Maybe later",
        //Input 
        name: "Name",
        phone: "Phone",
        subject: "Subject",
        sendMessage: "Send Message",
        message: "Message",
        // questionContact: "Do you have a big idea or a brand to develop and need help?",
        //Auth 
        welcomeAuth: "Welcome",
        WantVisited: "Would you like to visit our platform?",
        clickHere: "Click here",
        EmailAdress: "Email adress",
        password: "Password",
        ForgotPswd: "Forgot password ?",
        SingUp: "Sing up",
        NoAccount: "Don't have an account yet?",
        //Inscription 
        createAccount: "Create Account",
        ConfirmaPswd: "Confirm Password",
        student: "Student",
        teacher: "Teacher",
        HaveAccount: "Do you already have an account?",
        SingIn: "Sing in",
        //Certificat
        certificate: "Certificate",
        certificatCode: "Enter your certificate code",
        AuthenticCertificate: "Authentic Certificate Verification",
        gettingCertificateDescription: `Certificates attest that you have acquired certain knowledge or 
                                        skills after completing a course or training. Our institution has implemented an online certificate 
                                        verification system to ensure the authenticity and integrity of the certificates we issue. 
                                        This initiative strengthens trust in our certifications, simplifies verification for employers,
                                        and combats fraud. We use advanced technologies to guarantee the security and integrity of the data, 
                                        and we are proud to offer 
                                        our partners full transparency in the issuance of our certificates.`,
        searchCertificate: "Search",
        //Passer-examen
        lookResult: "Let's take a look at your scores",
        and: 'and',
        result: "Result",
        returnToCourse: "Return to course",
        //AddPoint
        decriptionFomartion: 'Covers only the training fees',
        decriptionFomartionCertificat: 'Includes training fees and a certificate at the end',
        formation: 'Training',
        severalsPackage: 'Covers the fees of several packages',
        buy200Credit: 'Buy 200 credits',
        buy500Credit: 'Buy 500 credits',
        buy700Credit: 'Buy 700 credits',
        //About Hay+
        aboutHayPlus: `Hay + is a community that brings together both people eager to share 
                        their knowledge and those wishing to learn. As Malagasy sages used to say,
                        "Knowledge is the greatest inheritance." 
                        May this inheritance be accessible to all those who thirst for learning and sharing.`,
        //FeedBack
        clientFeedBack: "Customer feedback",
    },

    // Traduction en espagnol (esp)
    esp: {
        //Language
        fr: "Francés",
        en: "Inglés",
        esp: "Español",
        ital: "Italiano",
        mg: 'Malgache',
        //Loading
        loading: "cargando",
        //Flag
        flag: "/Flag/spain.jpeg",
        //Navbar
        home: "Inicio",
        courses: "Cursos",
        contact: "Contacto",
        register: "Registro",
        login: "Iniciar sesión",
        certificat: "Certificado",
        addPoint: "Comprar crédito",
        exercise: "Ejercicio",
        about: "Acerca de",
        exam: "Examen",
        profile: "Perfil",
        user: "Usuario",
        //USER
        students: "estudiante",
        business_man: "hombre de negocios",
        employee: "empleado",
        colaborator: "colaborador",
        //Home
        welcome: "Bienvenido, estimado ",
        titleAfterWelcome: "Bienvenido a nuestra plataforma de formación y mejora profesional dedicada a empleados y profesionales ambiciosos.",
        search: "Buscar cursos",
        hi: "Hola",
        youHave: "Tienes",
        newEvent: "nuevos eventos",
        viewAllEvent: "Ver todos los eventos",
        dashboard: "Panel de control",
        chargeAccount: "Cargar cuenta",
        titreCarousel1: "Monitoreo regular del curso",
        descriptioCarousel1: "Realiza un seguimiento de tu progreso fácilmente con el monitoreo regular de nuestros cursos. Mantente al día con recordatorios y actualizaciones oportunas.",
        titreCarousel2: "Contenido de curso diverso",
        descriptioCarousel2: "Nuestros cursos ofrecen una rica combinación de clases en video, materiales textuales e imágenes para mejorar tu experiencia de aprendizaje.",
        titreCarousel3: "Certificación al completar",
        descriptioCarousel3: "Obtén un certificado reconocido al completar con éxito tu curso. Muestra tus nuevas habilidades y conocimientos a posibles empleadores.",
        //prof homecard 
        balance: "Saldo de la cuenta",
        purchased: "Cursos comprados",
        meeting: "Reuniones",
        yourStudent: "Tus estudiantes",
        //Cours
        yaCourse: "Tus cursos",
        interestingCourse: "Cursos interesantes",
        in: "En",
        hour: "horas",
        followed: "Seguidos",
        //Details cours
        createdBy: "Creado por",
        yllLearn: "¿Qué aprenderás?",
        aboutCourse: "Acerca de este curso",
        addCard: "Agregar al carrito",
        buy: "¡Comprar ahora!",
        payInstallment: "Pagar a plazos",
        lesson: "Lección",
        //Selection Part
        finished: 'Terminado',
        ongoing: "En curso",
        PassExam: "Pasar el examen",
        //Footer
        AboutUs: "Acerca de nosotros",
        privacyPolicy: "Política de privacidad",
        condition: "Términos de uso",
        plan: "Plan del sitio",
        droitDauteur: "Todos los derechos reservados",
        explore: "Explora, crece, aprende más",
        receiveThe: "Recibe el",
        ourDomain: "Nuestro dominio",
        ourAdress: "Nuestra dirección",
        sendEmail: "Enviar correo electrónico",
        sendFeedBaak: 'Enviar comentarios',
        //Contact
        infoContact: "Información de contacto",
        doyouHave: "¿Tienes alguna sugerencia o idea para mejoras?",
        contenu: "contenido",
        description: 'Descripción',
        title: 'Título',
        yaOpinionMatter: '¡Tu opinión es importante para nosotros !',
        MaybeLater: "Tal vez más tarde",
        //Input 
        name: "Nombre",
        phone: "Teléfono",
        subject: "Asunto",
        sendMessage: "Enviar mensaje",
        message: "Mensaje",
        //Auth 
        welcomeAuth: "Bienvenido",
        WantVisited: "¿Te gustaría visitar nuestra plataforma?",
        clickHere: "Haz clic aquí",
        EmailAdress: "Dirección de correo electrónico",
        password: "Contraseña",
        ForgotPswd: "¿Olvidaste tu contraseña?",
        SingUp: "Registrarse",
        NoAccount: "¿Aún no tienes una cuenta?",
        //Inscription 
        createAccount: "Crear cuenta",
        ConfirmaPswd: "Confirmar contraseña",
        student: "Estudiante",
        teacher: "Profesor",
        HaveAccount: "¿Ya tienes una cuenta?",
        SingIn: "Iniciar sesión",
        //Certificat
        certificate: "Certificado",
        certificatCode: "Introduce el código de tu certificado",
        AuthenticCertificate: "Verificación auténtica de certificado",
        gettingCertificateDescription: `Los certificados atestiguan que has adquirido ciertos conocimientos o 
                                    habilidades después de completar un curso o capacitación. Nuestra institución ha implementado un sistema de 
                                    verificación de certificados en línea para garantizar la autenticidad e integridad de los certificados que emitimos.`,
        searchCertificate: "Buscar",
        //Passer-examen
        lookResult: "Veamos tus resultados",
        and: 'y',
        result: "Resultado",
        returnToCourse: "Volver al curso",
        //AddPoint
        decriptionFomartion: 'Cubre solo los gastos de formación',
        decriptionFomartionCertificat: 'Incluye los gastos de formación y un certificado al final',
        formation: 'Formación',
        severalsPackage: 'Cubre los gastos de varios paquetes',
        buy200Credit: 'Comprar 200 créditos',
        buy500Credit: 'Comprar 500 créditos',
        buy700Credit: 'Comprar 700 créditos',
        aboutHayPlus: `Hay + es una comunidad que reúne tanto a personas deseosas de compartir su conocimiento 
                        como a aquellas que desean aprender. Como decían los sabios malgaches, 
                        "El conocimiento es la mayor herencia." 
                        Que esta herencia sea accesible para todos aquellos que tienen sed de aprender y transmitir.`,
        //FeedBack
        clientFeedBack: "Comentarios de los clientes",
    },

    // Traduction en italien (ital)
    ital: {
        //Language
        fr: "Francese",
        en: "Inglese",
        esp: "Spagnolo",
        ital: "Italiano",
        mg: 'Malgascio',
        //Loading
        loading: "caricamento",
        //Flag
        flag: "/Flag/italy.png",
        //Navbar
        home: "Home",
        courses: "Corsi",
        contact: "Contatto",
        register: "Registrati",
        login: "Accedi",
        certificat: "Certificato",
        addPoint: "Compra credito",
        exercise: "Esercizio",
        about: "Chi siamo",
        exam: "Esame",
        profile: "Profilo",
        user: "Utente",
        //USER
        students: "studente",
        business_man: "uomo d'affari",
        employee: "dipendente",
        colaborator: "collaboratore",
        //Home
        welcome: "Benvenuto, caro ",
        titleAfterWelcome: "Benvenuti sulla nostra piattaforma di formazione e aggiornamento dedicata ai dipendenti e ai professionisti ambiziosi.",
        search: "Cerca corsi",
        hi: "Ciao",
        youHave: "Hai",
        newEvent: "nuovi eventi",
        viewAllEvent: "Visualizza tutti gli eventi",
        dashboard: "Dashboard",
        chargeAccount: "Ricarica conto",
        titreCarousel1: "Monitoraggio regolare del corso",
        descriptioCarousel1: "Tieni traccia dei tuoi progressi con il monitoraggio regolare dei corsi sulla nostra piattaforma. Rimani aggiornato con promemoria tempestivi e aggiornamenti.",
        titreCarousel2: "Contenuti del corso diversificati",
        descriptioCarousel2: "I nostri corsi offrono una combinazione ricca di lezioni video, materiali testuali e immagini per migliorare la tua esperienza di apprendimento.",
        titreCarousel3: "Certificazione al completamento",
        descriptioCarousel3: "Ottieni una certificazione riconosciuta al termine con successo del tuo corso. Mostra le tue nuove competenze ai potenziali datori di lavoro.",
        //prof homecard 
        balance: "Saldo del conto",
        purchased: "Corsi acquistati",
        meeting: "Riunioni",
        yourStudent: "I tuoi studenti",
        //Cours
        yaCourse: "I tuoi corsi",
        interestingCourse: "Corsi interessanti",
        in: "In",
        hour: "ore",
        followed: "Seguiti",
        //Details cours
        createdBy: "Creato da",
        yllLearn: "Cosa imparerai?",
        aboutCourse: "A proposito di questo corso",
        addCard: "Aggiungi al carrello",
        buy: "Compra ora!",
        payInstallment: "Paga a rate",
        lesson: "Lezione",
        //Selection Part
        finished: 'Terminato',
        ongoing: "In corso",
        PassExam: "Supera l'esame",
        //Footer
        AboutUs: "Chi siamo",
        privacyPolicy: "Politica della privacy",
        condition: "Termini di utilizzo",
        plan: "Piano del sito",
        droitDauteur: "Tutti i diritti riservati",
        explore: "Esplora, cresci, impara di più",
        receiveThe: "Ricevi il",
        ourDomain: "Il nostro dominio",
        ourAdress: "Il nostro indirizzo",
        sendEmail: "Invia email",
        sendFeedBaak: 'Invia un feedback',
        //Contact
        infoContact: "Informazioni di contatto",
        doyouHave: "Hai suggerimenti o idee per miglioramenti?",
        contenu: "contenuto",
        description: "Descrizione",
        title: "Titolo",
        yaOpinionMatter: 'La tua opinione è importante per noi !',
        MaybeLater: `Forse più tardi`,
        //Input 
        name: "Nome",
        phone: "Telefono",
        subject: "Oggetto",
        sendMessage: "Invia messaggio",
        message: "Messaggio",
        //Auth 
        welcomeAuth: "Benvenuto",
        WantVisited: "Vuoi visitare la nostra piattaforma?",
        clickHere: "Clicca qui",
        EmailAdress: "Indirizzo email",
        password: "Password",
        ForgotPswd: "Hai dimenticato la password?",
        SingUp: "Registrati",
        NoAccount: "Non hai ancora un account?",
        //Inscription 
        createAccount: "Crea account",
        ConfirmaPswd: "Conferma password",
        student: "Studente",
        teacher: "Insegnante",
        HaveAccount: "Hai già un account?",
        SingIn: "Accedi",
        //Certificat
        certificate: "Certificato",
        certificatCode: "Inserisci il codice del certificato",
        AuthenticCertificate: "Verifica autentica del certificato",
        gettingCertificateDescription: `I certificati attestano che hai acquisito determinate competenze o conoscenze 
                                     al termine di un corso o formazione. La nostra istituzione ha implementato un sistema di 
                                     verifica dei certificati online per garantire l'autenticità e l'integrità dei certificati che emettiamo.`,
        searchCertificate: "Cerca",
        //Passer-examen
        lookResult: "Vediamo i tuoi risultati",
        and: 'e',
        result: "Risultato",
        returnToCourse: "Torna al corso",
        //AddPoint
        decriptionFomartion: ' Copre solo i costi di formazione',
        decriptionFomartionCertificat: 'Include i costi di formazione e un certificato alla fine',
        formation: 'Formazione',
        severalsPackage: 'Copre i costi di diversi pacchetti',
        buy200Credit: 'Acquistare 200 crediti',
        buy500Credit: 'Acquistare 500 crediti',
        buy700Credit: 'Acquistare 700 crediti',
        aboutHayPlus: `Hay + è una comunità che riunisce sia persone desiderose di condividere le proprie 
        conoscenze sia coloro che desiderano apprendere. Come dicevano i saggi malgasci,
         "La conoscenza è la più grande delle eredità." 
        Che quest'eredità sia accessibile a tutti coloro che hanno sete di imparare e trasmettere.`,
        //FeedBack
        clientFeedBack: "Feedback dei clienti",
    },

    //Traduction en Malagasy
    mg: {
        //Language
        fr: "Frantsay",
        en: "Anglisy",
        esp: "Espaniola",
        ital: "Italiana",
        mg: 'Malagasy',
        //Loading
        loading: "Mampiditra",
        //Flag
        flag: "/Flag/Mada.png",
        //NavBar
        home: "Fandraisana",
        courses: "Fampianarana",
        contact: "Mifandray",
        register: "Misoratra anarana",
        login: "Hiditra",
        exam: "Fanadinana",
        profile: "Piraofilina",
        certificat: "Fanamarinana",
        addPoint: "Mividy fahana",
        exercise: "Fanazaran-tena",
        about: "Momba",
        user: "Mpampiasa",
        //USER
        students: "mpianatra",
        employee: "mpiasa",
        business_man: "mpandraharaha",
        colaborator: "mpiara-miasa",
        //Home
        welcome: "Tonga soa ry ",
        titleAfterWelcome: "Tongasoa eto amin'ny sehatra fampianarana sy fanavaozana ho an'ny mpiasa sy ny matihanina mikatsaka fahombiazana.",
        search: "Mitady fampianarana",
        hi: "Salama",
        youHave: "Manana",
        newEvent: "hetsika vaovao",
        viewAllEvent: "Hijery ireo hetsika rehetra",
        dashboard: "Dashboard",
        chargeAccount: "Mameno kaonty",
        titreCarousel1: "Fanaraha-maso ny Fampianarana",
        descriptioCarousel1: "Manaraha mora foana ny fandrosoanao amin'ny alàlan'ny fanaraha-maso tsy tapaka ny fampianarana. Mijanona amin'ny tanjon'ny fianaranao miaraka amin'ny fampahatsiahivana sy fanavaozana tsy tapaka.",
        titreCarousel2: "Fampianarana Maro samihafa",
        descriptioCarousel2: "Ny fampianarana atolotray dia manolotra fitambarana feno amin'ny horonan-tsary, taratasy ary sary mba hanatsarana ny fahazoanao. Mirotsaha an-tsehatra amin'ny votoaty amin'ny endrika samihafa ho an'ny fahatakarana feno.",
        titreCarousel3: "Fanamarinana amin'ny fiafaran'ny Fampianarana",
        descriptioCarousel3: "Mahazoa fanamarinana ekena rehefa vita ny fiofanana. Ampisehoy ny fahaizanao sy ny fahalalanao vaovao amin'ny mpampiasa mety.",
        //prof homecard 
        balance: "Salan'isa kaonty",
        purchased: "Fampianarana novidiana",
        meeting: "fihaonana",
        yourStudent: "Mpianatrao",
        //Cours
        yaCourse: "Fampianaranao",
        interestingCourse: "Fampianarana mahaliana",
        in: "Ao",
        hour: "ora",
        followed: "Narahina",
        //Details cours
        createdBy: "Noforonina avy amin'i",
        yllLearn: "Inona no hianaranao ?",
        aboutCourse: "Momba ity fampianarana ity",
        addCard: "Ampiana amin'ny karatra",
        buy: "Vidio izao!",
        payInstallment: "Aloavy tsikelikely",
        lesson: 'Lesona',
        //Selection Part
        finished: 'Vita',
        ongoing: "Mbola mandeha",
        PassExam: "Mandeha fanadinana",
        //Profile 
        following: "Manaraka",
        follower: "Mpankasitraka",
        //Footer
        AboutUs: "Momba anay",
        privacyPolicy: "Politika momba ny tsiambaratelo",
        condition: "Fepetran'ny fampiasana",
        plan: "Planin'ny tranonkala",
        droitDauteur: "Zo rehetra voatokana",
        explore: "Hijery, hitombo, hianatra bebe kokoa",
        receiveThe: "Mandray ny",
        ourDomain: "Sehatra misy anay",
        ourAdress: "Adiresy misy anay",
        sendEmail: "Mandefasa mailaka",
        sendFeedBaak: 'Alefaso ny feedback',
        //Contact
        infoContact: "Fampahalalana momba ny fifandraisana",
        doyouHave: 'Manana soso-kevitra sa hevitra fanatsarana ve ianao?',
        contenu: "Hafatra",
        description: "Famaritana",
        title: "Lohateny",
        yaOpinionMatter: ' Zava-dehibe aminay ny hevitrao !',
        MaybeLater: `Angamba amin'ny manaraka`,
        //Input: 
        name: "Anarana",
        phone: "Telefaonina",
        subject: "Lohahevitra",
        sendMessage: "Mandefa hafatra",
        message: "Hafatra",
        //Auth
        welcomeAuth: "Tonga soa",
        WantVisited: "Te hitsidika ny sehatra ve ianao?",
        clickHere: "Tsindrio eto",
        EmailAdress: "Adiresy Email",
        password: "Teny miafina",
        ForgotPswd: "Adino ny teny miafina?",
        SingUp: "Misoratra anarana",
        NoAccount: "Tsy manana kaonty ve ianao?",
        //Inscription
        createAccount: "Mametraka kaonty",
        ConfirmaPswd: "Hamafiso ny teny miafina",
        student: "Mpianatra",
        teacher: "Mpampianatra",
        HaveAccount: "Manana kaonty ve ianao?",
        SingIn: "Hiditra",
        //Certificat
        certificate: "Fanamarinana",
        certificatCode: "Ampidiro ny code fanamarinana",
        AuthenticCertificate: 'Fanamarinana ara-dalàna ny Taratasy fanamarinana',
        gettingCertificateDescription: `Ny taratasy fanamarinana dia manamarina fa manana fahalalana
                    na fahaiza-manao ianao rehefa avy nanao fiofanana. Ny
                    andrimpananay dia nametraka rafitra fanamarinana taratasy fanamarinana an
                    -tserasera mba hiantohana ny maha-azo antoka sy ny fahamendrehan'ny taratasy
                    fanamarinana izahay. Io fepetra io dia mampitombo ny fitokisana amin'ny
                    fanamarinana azonay, manamora ny fanamarinana ho an'ny mpampiasa, ary
                    miady amin'ny hosoka. Mampiasa teknolojia avo lenta izahay mba
                    hiantohana ny fiarovana sy ny maha-toky ny angon-drakitra, ary mirehareha
                    izahay manome mangarahara tanteraka ho an'ny mpiara-miombon'antoka aminay
                    amin'ny fanomezana ny taratasy fanamarinanay.`,
        searchCertificate: "Mitady fanamarinana",
        //Passer examen
        lookResult: "Andeha hijery ny vokatrao",
        and: "sy",
        result: "Vokatra",
        returnToCourse: "Miverina amin'ny fampianaranao",
        //AddPoint
        decriptionFomartion: 'Mandrakotra ny saram-piofanana fotsiny',
        decriptionFomartionCertificat: 'Mandrakotra ny saram-piofanana sy taratasy fanamarinana rehefa vita',
        formation: 'Fiofanana',
        severalsPackage: 'Mandrakotra ny saram-piofanana maromaro',
        buy200Credit: 'Mividy fahana 200',
        buy500Credit: 'Mividy fahana 500',
        buy700Credit: 'Mividy fahana 700',
        aboutHayPlus: `Hay + dia vondrom-piarahamonina iray mitambatra ny olona maniry hizara ny fahalalany
                        sy ireo te hianatra. Araka ny nolazain’ny mpahay tsara malagasy, 
                        "Ny fianarana no lova tsara indrindra."
                        Aoka ho azon'ny rehetra mitady hianatra sy hampita izany lova izany.`,
        //FeedBack
        clientFeedBack: "Fanehoan-kevitra avy amin'ny mpanjifa",
    },
};

function translate(language, key) {
    return translations[language][key];
}

export default translate;
