import { useSearchParams } from "react-router-dom";
import { CheckBoxInput } from "../Input/CheckBoxInput";

export const FiltreParPack = ({ categorie, isAlign }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const handleOptionChange = (e) => {
        const value = e.target.value;
        const selectedOptions = searchParams.get("pack") ? searchParams.get("pack").split(",") : [];
        const newSelectedOptions = selectedOptions.includes(value)
            ? selectedOptions.filter(i => i !== value)
            : [...selectedOptions, value];

        setSearchParams({ pack: newSelectedOptions.join(",") });
    };

    return (
        <div className={`w-full h-full flex flex-col items-center justify-center`}>
            <div className={`${isAlign ? "flex flex-wrap w-[85%] justify-around py-3 rounded shadow-cardShadow mb-7 z-10 bg-white" : "w-full"}`}>
                {categorie?.flatMap((cat) =>
                    cat.cours
                        .filter(c => c.id_pack)
                        .map((cour) => (
                            <CheckBoxInput
                                key={cour.id_pack}
                                value={cour.id_pack}
                                name="pack"
                                onChange={handleOptionChange}
                                label={`Pack ${cour.id_pack}`}
                                checked={searchParams.get("pack") ? searchParams.get("pack").split(",").includes(cour.id_pack.toString()) : false}
                            />
                        ))
                )}
            </div>
        </div>
    );
};
