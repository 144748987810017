import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { OSInput } from "../../../Components/Input/OSInput";
import api, { baseUrlImage } from "../../../store/Api/Intercepteur";
import { OSButton } from "../../../Components/Button/OSButton";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const Paiment = () => {
    const [FormationType, setFormationType] = useState("");
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef();
    const [credit, setCredit] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const zoomImage = useRef()
    const [paiment, setPaiment] = useState([])
    const [onglet, setOnglet] = useState(0)
    const [image, setImage] = useState(null)
    const [defaultValue, setDefaultValue] = useState()
    const [id, setId] = useState(0)
    const [isRefresh, setIsaRefresh] = useState(false)
    const [historique, setHistorique] = useState([])
    const [idUser, setIdUser] = useState(0)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (zoomImage.current && !zoomImage.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleOpen = () => {
        setIsOpen(true);
    };;

    const handleZoomIn = () => {
        setZoomLevel((prev) => prev + 0.2);
    };

    const handleZoomOut = () => {
        setZoomLevel((prev) => Math.max(1, prev - 0.2));
    };

    const fetchPaiment = async () => {
        try {
            const response = await api.get('/screenShot',
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            )
            setPaiment(() => {
                return response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    const fetchHistorique = async () => {
        try {
            const response = await api.get('/historiques',
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            )
            setHistorique(() => {
                console.log(response.data)
                return response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (onglet === 0) {
            fetchPaiment()
        } else {
            fetchHistorique()
        }
    }, [onglet, isRefresh])

    const selectPaiment = (image, type, id, userId) => {
        setFormationType(type)
        setId(id)
        setIdUser(userId)
        if (type === "formation") setDefaultValue(150)
        else if (type === "formation + certificat") setDefaultValue(350)
        else setDefaultValue(700)
        setImage(image)
    }

    const onRefresh = () => {
        setIsaRefresh(!isRefresh)
    }

    //Paiment 

    const validation = async () => {
        try {
            await api.post(`/validated/${id}`, { credit: credit }, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            await api.post(`/historiques`,
                {
                    capture: image,
                    credit: credit,
                    id_user: idUser
                },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            )
            await api.delete(`/screenShot/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
            onRefresh()
            toast.success("Validation terminée avec succès!")
            setShowModal(false)
        } catch (error) {
            console.log(error)
        }
    }

    const AnnulerClicked = async () => {
        setCredit(0)
        validation()
        setId(0)
    }

    const HandleSubmit = (e) => {
        e.preventDefault()
        validation()
        setId(0)
    }

    const PaimentCard = ({ user, hour, type, image, ids, userId }) => {
        return (
            <div>
                <button onClick={() => selectPaiment(image, type, ids, userId)} className="w-full px-3 py-1 mt-3 bg-white rounded flex justify-center items-center">
                    <div
                        tabIndex="0"
                        aria-label="post icon"
                        role="img"
                        className="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center"
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.30325 12.6667L1.33325 15V2.66667C1.33325 2.48986 1.40349 2.32029 1.52851 2.19526C1.65354 2.07024 1.82311 2 1.99992 2H13.9999C14.1767 2 14.3463 2.07024 14.4713 2.19526C14.5963 2.32029 14.6666 2.48986 14.6666 2.66667V12C14.6666 12.1768 14.5963 12.3464 14.4713 12.4714C14.3463 12.5964 14.1767 12.6667 13.9999 12.6667H4.30325ZM5.33325 6.66667V8H10.6666V6.66667H5.33325Z"
                                fill="#4338CA"
                            />
                        </svg>
                    </div>
                    <div className="pl-3 w-full">
                        <p tabIndex="0" className="focus:outline-none text-sm leading-none text-start">
                            <span className="text-indigo-700">{user}</span> a effectué un paiment
                            {onglet === 0 &&
                                (
                                    <span>pour <span className="text-indigo-700">{type}</span></span>
                                )
                            }
                        </p>
                        <p tabIndex="0" className="focus:outline-none text-xs pr-5 pt-3 text-gray-500 w-full text-end">
                            {hour} hours ago
                        </p>
                    </div>
                </button>
            </div>
        );
    };

    return (
        <div className="min-h-screen pl-60 w-full mt-16 flex gap-x-28 max-lg:gap-x-10">
            <div className="w-96 h-[100vh] overflow-y-auto border-r">
                <div tabIndex="0" className="focus:outline-none text-sm leading-normal pt-8 border-b border-gray-300 text-gray-600">
                    <button className="w-1/2" onClick={() => setOnglet(0)}>
                        <p className=" pb-2">PAS ENCORE VALIDÉ</p>
                        <p className={`w-full h-1 ${onglet === 0 ? "bg-primary" : ""}`}></p>
                    </button>
                    <button className="w-1/2" onClick={() => setOnglet(1)}>
                        <p className=" pb-2">HISTORIQUE</p>
                        <p className={`w-full h-1 ${onglet === 1 ? "bg-primary" : ""}`}></p>
                    </button>
                </div>
                <div className="w-full">
                    {onglet === 0 &&
                        paiment?.map((item, index) => (
                            <PaimentCard key={index} user={item.user.name} hour={2} type={item.type} image={item.image} ids={item.id} userId={item.user.id} />
                        ))
                    }
                    {
                        onglet === 1 &&
                        historique?.map((item, index) => (
                            <PaimentCard key={index} user={item.users?.name} hour={2} type={item.type} image={item.capture} ids={item.id} userId={item.users?.id} />
                        ))
                    }
                </div>
            </div>
            {image ? (
                <div className="mt-5">
                    {
                        onglet === 0 && (
                            <button
                                onClick={() => setShowModal(true)}
                                className="w-full mb-7 rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-bold uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                            >
                                Valider paiement
                            </button>
                        )
                    }
                    <div className="h-[500px] w-[400px] max-lg:w-72 max-lg:h-96 cursor-pointer" onClick={handleOpen}>
                        <img
                            src={`${baseUrlImage}/storage/${image}`}
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                </div>
            ) : (
                <div></div>
            )
            }
            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                    shouldCloseOnOverlayClick={true}
                >
                    <div ref={zoomImage} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh]  mt-10 ml-[10vw]">
                        <div
                            className="relative"
                            style={{ transform: `scale(${zoomLevel})` }}
                        >
                            <img
                                src={`${baseUrlImage}/storage/${image}`}
                                alt=""
                                className="w-auto max-h-full object-contain"
                            />
                            <div className="absolute bottom-2 right-2 flex space-x-2">
                                <button
                                    onClick={handleZoomIn}
                                    className="text-white bg-gray-800 p-2 rounded"
                                >
                                    +
                                </button>
                                <button
                                    onClick={handleZoomOut}
                                    className="text-white bg-gray-800 p-2 rounded"
                                >
                                    -
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                shouldCloseOnOverlayClick={true}
            >
                <div ref={modalRef} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-24 ml-[10vw]">
                    <div className="flex justify-between items-center p-5 border-b">
                        <h3 className="text-lg font-medium">Paiement pour {FormationType}</h3>
                        <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray                            -700 transition duration-150 ease-in-out focus:outline-none">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                    <form className="px-6 py-3" onSubmit={HandleSubmit}>
                        <OSInput placeholder="Credit" id="paiment" type="number" defaultValue={defaultValue} state={((e) => setCredit(e.target.value))} />
                        <div className="flex gap-x-16">
                            <button onClick={AnnulerClicked} className="text-light bg-red-600 rounded font-bold text-white w-40 text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">Refuser</button>
                            <OSButton value="Valider" />
                        </div>
                    </form>
                </div>
            </Modal>
            <ToastContainer />
        </div>
    );
};
