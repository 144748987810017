import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store/User/user";
import api, { apiOllama } from "../../store/Api/Intercepteur";
import { AucunElement } from "../../Components/Cards/AucunElementCard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import DeleteCard from "../../Components/DeleteConfirmation/DeleteCard";
import { CheckBoxInput } from "../../Components/Input/CheckBoxInput";

function ViewChapitre() {
  const type = useAppStore.use.type();
  const location = useLocation();
  const items = location.state.items || [];
  const tousCours = location.state.tousCours || [];
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentChapter, setCurrentChapter] = useState([]);
  const [idchapitre, setIdChapitre] = useState(location.state.idchapitre);
  const [showSuppressionModal, setShowSuppressionModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [exercice, seExercice] = useState([]);

  console.log(tousCours);
  const modalSuppressionRef = useRef(null);

  useEffect(() => {
    const index = items.findIndex((i) => i.id === idchapitre);
    setCurrentChapter(items[index]);
    setCurrentIndex(index);
  }, []);

  const ModifierChapitreClicked = () => {
    navigate("/Chapitre/modification-chapitre", {
      state: { items: items, chapitre: currentChapter },
    });
  };

  const SupprimerClicked = async () => {
    setShowSuppressionModal(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`chapitres/${currentChapter.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setShowSuppressionModal(false);
      toast.success("Chapitre supprimé avec succès!", {
        onClose: () => {
          if (currentIndex < items.length - 1) {
            setCurrentChapter(items[currentIndex + 1]);
            setCurrentIndex(currentIndex + 1);
          } else if (currentIndex > 0) {
            setCurrentChapter(items[currentIndex - 1]);
            setCurrentIndex(currentIndex - 1);
          } else {
            setIdChapitre(-1);
          }
        },
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowSuppressionModal(false);
        toast.error(
          "Une erreur s'est produite lors de la suppresion du chapitre!"
        );
      }
      console.log(error);
    }
  };

  const cancelDelete = async () => {
    setShowSuppressionModal(false);
  };

  const next = () => {
    if (currentIndex < items.length - 1) {
      setCurrentChapter(items[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const previous = () => {
    if (currentIndex > 0) {
      setCurrentChapter(items[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalSuppressionRef.current &&
        !modalSuppressionRef.current.contains(event.target)
      ) {
        setShowSuppressionModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (idchapitre < 0) {
    console.error("Index out of bounds");
    return (
      <div className="min-h-screen w-full flex justify-center items-center">
        <div className="w-1/3">
          <AucunElement item="contenu" />
        </div>
      </div>
    );
  }

  const fetchExplication = async (contenu) => {
    toggleDialogue();
    try {
      const response = await fetch(`${apiOllama}/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: `Veuillez m'expliquer en détail le contenu: "${contenu}" du chapitre ${currentChapter.titre} dont le nom du cours est ${tousCours.nom} dans la catégorie ${tousCours.categorie}`,
        }),
      });

      const text = await response.text();
      if (!text) {
        throw new Error("La réponse est vide.");
      }

      const lines = text.split("\n").filter((line) => line.trim());

      let combinedMessage = "";

      lines.forEach((line) => {
        try {
          const parsedLine = JSON.parse(line);
          if (parsedLine.message && parsedLine.message.content) {
            combinedMessage += parsedLine.message.content;
          }
        } catch (e) {
          console.error("Erreur de parsing pour la ligne :", line, e);
        }
      });
      setMessage(combinedMessage);
    } catch (error) {
      console.error("Erreur lors de l'envoi du message :", error);
    }
  };

  const fetchExercice = async (contenu) => {
    toggleDialogue();
    try {
      const response = await fetch(`${apiOllama}/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: `Veuillez générer un tableau JSON de 5 questions différents de type QCM sur le contenu du cours: "${contenu}" du chapitre "${currentChapter.titre}" dans la catégorie "${tousCours.categorie}". Le JSON doit être un tableau d'objets, chaque objet représentant un exercice avec les propriétés suivantes : "question" (la question), "options" (un tableau des options de réponse), et "good_response" (l'indice de la bonne réponse). Retournez uniquement un tableau JSON brut sans texte explicatif ni autre contenu.`,
        }),
      });

      const text = await response.text();
      if (!text) {
        throw new Error("La réponse est vide.");
      }

      const lines = text.split("\n").filter((line) => line.trim());

      let combinedMessage = "";

      lines.forEach((line) => {
        try {
          const parsedLine = JSON.parse(line);
          if (parsedLine.message && parsedLine.message.content) {
            combinedMessage += parsedLine.message.content;
          }
        } catch (e) {
          console.error("Erreur de parsing pour la ligne :", line, e);
        }
      });
      setMessage(combinedMessage);

      const jsonStartIndex = combinedMessage.indexOf("[");
      const jsonEndIndex = combinedMessage.lastIndexOf("]") + 1;

      if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
        const jsonString = combinedMessage.slice(jsonStartIndex, jsonEndIndex);
        try {
          const jsonParsed = JSON.parse(jsonString);
          seExercice(jsonParsed);
        } catch (error) {
          console.error("Erreur lors du parsing JSON:", error);
        }
      } else {
        try {
          const jsonParsed = JSON.parse(combinedMessage);
          seExercice(jsonParsed);
        } catch (error) {
          console.error("Erreur lors du parsing JSON:", error);
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message :", error);
    }
  };

  const toggleDialogue = async () => {
    setMessage("");
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full px-52 pt-2 min-h-screen mt-14">
      {type === "enseignant" ? (
        <div className="ml-20 mt-3">
          <div className="flex justify-between">
            <button
              onClick={previous}
              disabled={currentIndex === 0}
              className={`flex items-center rounded-lg px-2 font-semibold text-secondary hover:text-white hover:bg-primary border border-secondary ${
                currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </button>
            <p className="text-center text-3xl font-bold text-gray-800">
              {currentChapter.titre}
            </p>
            <div className="flex">
              <button
                onClick={() => fetchExercice(currentChapter.contenues)}
                className="mr-3 px-4 py-1.5 rounded font-bold bg-primary text-white hover:bg-primary/80"
              >
                Exercice
              </button>
              <div
                className={`fixed top-28 right-12 h-[80vh] flex flex-1 w-1/2 bg-white shadow-xl transform transition-transform ${
                  isOpen ? "translate-x-0" : "hidden"
                }`}
              >
                <div className="p-4 relative h-[80vh] flex-1 overflow-y-auto flex-col justify-between">
                  <div className="flex flex-col">
                    <h2 className="text-xl font-bold">
                      {currentChapter.titre}
                    </h2>
                    <p>{exercice.length == 0 ? "En train d'ecrire" : ""}</p>
                    {exercice.map((item, index) => (
                      <div key={index} className="">
                        <CheckBoxInput name="" label={item.question} />
                        <div>
                          {item.options.map((i, j) => (
                            <p>{i}</p>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex absolute  gap-2 right-4  py-2">
                    <button
                      className="mt-4 px-4 py-2 bg-primary text-white rounded"
                      onClick={toggleDialogue}
                    >
                      Enregistrer
                    </button>
                    <button
                      className="mt-4 px-4 py-2 bg-slate-500 text-white rounded"
                      onClick={toggleDialogue}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
              <button
                onClick={ModifierChapitreClicked}
                className="mr-3 px-4 py-1.5 rounded font-bold bg-primary text-white hover:bg-primary/80"
              >
                Modifier
              </button>
              <button
                onClick={SupprimerClicked}
                className="text-secondaryblue px-4 py-1.5 rounded font-bold bg-gray-300 mr-3 hover:bg-gray-300/80"
              >
                Supprimer
              </button>
              <button
                onClick={next}
                disabled={currentIndex === items.length - 1}
                className={`flex items-center rounded-lg border text-secondary hover:text-white border-secondary hover:bg-secondary px-3 font-semibold ${
                  currentIndex === items.length - 1
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faArrowRightLong} />
              </button>
            </div>
          </div>
          <div className="p-5 border mt-3 border-gray-200 rounded-lg h-screen overflow-y-auto scrollbar-thin scrollbar-w-2 scrollbar-track-rounded-md scrollbar-thumb-light scrollbar-thumb-rounded-full">
            <div
              dangerouslySetInnerHTML={{ __html: currentChapter.contenues }}
            />
          </div>
          <ToastContainer />
        </div>
      ) : (
        <div>
          <div className="flex justify-between">
            <button
              onClick={previous}
              disabled={currentIndex === 0}
              className={`flex items-center rounded-lg px-2 font-semibold text-secondary hover:text-white hover:bg-primary border border-secondary ${
                currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </button>
            <p className="text-center text-3xl font-bold text-gray-800">
              {currentChapter.titre}
            </p>
            <div className="flex">
              <button
                onClick={next}
                disabled={currentIndex === items.length - 1}
                className={`flex items-center rounded-lg border text-secondary hover:text-white border-secondary hover:bg-secondary px-3 font-semibold ${
                  currentIndex === items.length - 1
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faArrowRightLong} />
              </button>
            </div>
          </div>
          <div className="p-5 border mt-3 border-gray-200 rounded-lg h-screen overflow-y-auto scrollbar-thin scrollbar-w-2 scrollbar-track-rounded-md scrollbar-thumb-light scrollbar-thumb-rounded-full">
            <div className="flex justify-end mb-2">
              <button
                className="bg-secondary text-white font-medium px-4 py-2 rounded-lg"
                onClick={() => fetchExplication(currentChapter.contenues)}
              >
                Explication
              </button>
              <div
                className={`fixed top-28 right-12 h-[80vh] flex flex-1 w-1/4 bg-white shadow-xl transform transition-transform ${
                  isOpen ? "translate-x-0" : "hidden"
                }`}
              >
                <div className="p-4 relative h-[80vh] flex-1 overflow-y-auto flex-col justify-between">
                  <div className="flex flex-col">
                    <h2 className="text-xl font-bold">
                      {currentChapter.titre}
                    </h2>
                    <p>{message !== "" ? message : "En train d'ecrire"}</p>
                  </div>
                  <div className="flex absolute right-4  py-2">
                    <button
                      className="mt-4 px-4 py-2 bg-slate-500 text-white rounded"
                      onClick={toggleDialogue}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: currentChapter.contenues }}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={showSuppressionModal}
        onRequestClose={() => setShowSuppressionModal(false)}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        overlayclassName="fixed inset-0 bg-black bg-opacity-50"
        shouldCloseOnOverlayClick={true}
      >
        <div
          ref={modalSuppressionRef}
          className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-20 ml-[20vw]"
        >
          <DeleteCard onCancel={cancelDelete} onConfirm={confirmDelete} />
        </div>
      </Modal>
    </div>
  );
}

export default ViewChapitre;
