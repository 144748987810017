import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { LoginPswrdInput } from "../../Components/Input/LoginPswrdInput";
import { LoginInput } from "../../Components/Input/LoginInput";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { useAppStore } from "../../store/User/user";
import { Link } from "react-router-dom";
import { UseLanguage } from "../../store/User/language";
import translate from "../../store/Translation/Langue";

export const Auth = ({ handleCloseModal, trigersuivi }) => {
    const [name, setname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setpassword] = useState("")
    const [confiramtionpassword, setConfirmationpassword] = useState("")
    const type_utilisateur = "etudiant"
    const [inscription, setInscription] = useState(true)
    const register = useAppStore.use.register()
    const login = useAppStore.use.login()
    const [error, setError] = useState("");
    const language = UseLanguage.use.language()

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setError("Le mot de passe doit contenir au moins 8 caractères.");
        } else {
            setError("");
            if (confiramtionpassword === password) {
                const result = await register(name, email, password, type_utilisateur)
                if (result.success) {
                    setInscription(false)
                }
            }
            else {
                setError("Le mot de passe doit etre la meme.")
            }
        }
    };

    const handleSubmitAuth = async (e) => {
        e.preventDefault();
        const result = await login(email, password);
        if (result.success) {
            handleCloseModal()
        } else {
            console.log(result.error);
        }
    }

    const modalRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleCloseModal()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [handleCloseModal])

    return (
        <div className="flex bg-white flex-col items-start pb-10 w-80 rounded-lg" ref={modalRef}>
            <div className="flex justify-between items-center w-full p-2">
                <h1 className="font-semibold text-gray-900 text-xl">{inscription ? "Inscription" : "Se connecter"}</h1>
                <button onClick={handleCloseModal} className="">
                    <FontAwesomeIcon icon={faXmark} className="text-gray-600 w-5 h-5" />
                </button>
            </div>
            {
                inscription ? (
                    <div className="w-full px-5">
                        <p className="my-1 text-gray-600 text-center">Vous devez vous inscrire pour être éligible aux cours.</p>
                        <form onSubmit={handleSubmit} className="max-w-xs w-full mt-7">
                            <LoginInput icon={faUser} id="name" placeholder="name" state={((e) => setname(e.target.value))} />
                            <LoginInput icon={faEnvelope} id="name" placeholder="email" state={((e) => setEmail(e.target.value))} />
                            <LoginPswrdInput id="Mot_de_passe" error={error} placeholder="Mot de passe" state={((e) => setpassword(e.target.value))} />
                            <LoginPswrdInput id="confirmMdp" error={error} placeholder="Confirmer le mot de passe" state={((e) => setConfirmationpassword(e.target.value))} />
                            <button type="submit" className="text-light bg-primary text-white font-bold rounded w-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">
                                S'inscrire
                            </button>
                        </form>
                        <p className="text-gray-600 mt-4 text-sm">
                            Vous avez déjà un compte? <button onClick={() => setInscription(false)} className="text-blue-gray-800 font-bold">Se connecter</button>
                        </p>
                    </div>
                ) : (
                    <div className="w-full px-5 pt-7">
                        <form onSubmit={handleSubmitAuth} className="max-w-xs w-full">
                            <LoginInput icon={faUser} id="Email" placeholder="Email" state={((e) => setEmail(e.target.value))} />
                            <LoginPswrdInput id="Mot_de_passe" placeholder="Mot de passe" state={((e) => setpassword(e.target.value))} />
                            <Link to="/resetPassword" className="text-xs max-md:hidden hover:underline text-gray-500">{translate(language, "ForgotPswd")}</Link>
                            <button type="submit" className="text-light bg-primary text-white font-bold rounded w-full text-sm mt-1 px-5 py-2.5 me-2 mb-2 focus:outline-none">
                                Connexion
                            </button>
                        </form>
                        <p className="text-gray-600 mt-4 text-sm text-center">
                            Vous n’avez pas de compte ? <button onClick={() => setInscription(true)} className="text-blue-gray-800 font-bold"> M’inscrire</button>
                        </p>
                    </div>
                )
            }
        </div>
    );
};
