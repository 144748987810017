import React, { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchBar } from "../Components/Searchbar/SearchBar";
import api from "../store/Api/Intercepteur";
import { useAppStore } from "../store/User/user";
import { CoursCard } from "../Components/Cards/Etudiant/CoursCard";
import { AucunElement } from "../Components/Cards/AucunElementCard";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";
import translate from "../store/Translation/Langue";
import { UseLanguage } from "../store/User/language";

export const Recherche = () => {
    const [resultat, setResultat] = useState([]);
    const [categorie, setCategorie] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const recherche = searchParams.get('q') || '';
    const type = useAppStore.use.type();
    const [dashboard, setDashboard] = useState([])
    const language = UseLanguage.use.language()

    const fetchData = async () => {
        try {
            const endpoint = type === "etudiant" ? '/categories' : '/visiteurs';
            const headers = type === "etudiant" ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {};

            const response = await api.get(endpoint, { headers });
            setCategorie(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchNombreCours = async () => {
        try {
            const response = await api.get('/dashboard')
            setDashboard(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData();
        fetchNombreCours()
    }, [type]);

    const handleSearch = (e) => {
        if (e) {
            e.preventDefault()
        }
        if (!categorie.length) return;

        if (recherche !== '') {
            const filteredResult = categorie.flatMap(cat =>
                cat.cours.filter(crs => crs.nom.toLowerCase().includes(recherche.toLowerCase()))
                    .map(c => {
                        const isSuivi = type === "etudiant" && c.user_cours.length !== 0;
                        return {
                            ...c,
                            categorie: cat.nom,
                            isSuivi,
                            enseignant: c.user.name
                        };
                    })
            );

            setResultat(filteredResult);
            setTimeout(() => {
                window.scrollTo({
                    top: window.innerHeight,
                    behavior: "smooth"
                });
            }, 100);
        }
    }

    useEffect(() => {
        if (!isLoading) {
            handleSearch();
        }
    }, [isLoading]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className={`${type === 'administrateur' ? "ml-60" : ''} relative flex flex-col min-h-screen items-center justify-center`}>
            <div
                className="absolute inset-0 bg-cover bg-center filter w-full h-[100vh]"
                style={{
                    backgroundImage: `url('https://media.istockphoto.com/id/1663317152/fr/photo/jeune-femme-caucasienne-utilisant-un-ordinateur-portable-tout-en-travaillant-%C3%A0-domicile.webp?b=1&s=170667a&w=0&k=20&c=N58-jNPm3pq-ypXW3RvUgRww-jPsgRCTUo_McM5_dDo=')`,
                    backgroundSize: "cover",
                }}
            >
                <div className="w-full h-full bg-black/70"></div>
            </div>
            <div className="relative top-[-40px] flex w-1/2 h-[100vh] max-sm:w-full flex-col items-center justify-center text-gray-200">
                <div className="flex flex-col items-center mb-5">
                    <p className="m-2 text-3xl font-bold">Cours</p>
                    <p className="mb-2 py-1.5 px-7 rounded-md bg-gray-400/60 font-bold">{dashboard?.nbCours} {translate(language, 'courses')}{language === 'en' ? 's' : ''}</p>
                </div>
                <div className="w-4/5 max-sm:w-5/6">
                    <SearchBar
                        placeholder={translate(language, 'search')}
                        state={(e) => setSearchParams({ q: e.target.value })}
                        onClick={handleSearch}
                    />
                </div>
            </div>
            {
                resultat.length > 0 ? (
                    <div className={`${type === 'administrateur' ? 'px-36' : ''} px-52 w-full my-20 min-h-[100vh] flex justify-start flex-wrap gap-x-28 items-start`}>
                        {resultat.map((item, index) => (
                            <CoursCard key={index} items={item} isSuivi={item.isSuivi} />
                        ))}
                    </div>
                ) : (
                    recherche !== '' &&
                    <div className="w-full px-52 flex justify-center min-h-[100vh] items-center">
                        <div className="w-96 h-96" >
                            <AucunElement item={'cours'} />
                        </div>
                    </div>
                )
            }
        </div>
    );
};
