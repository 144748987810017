import React, { useEffect, useState } from "react"
import { UtilisateurCard } from "../../../Components/Cards/Admin/UtilisateurCard"
import api from "../../../store/Api/Intercepteur"
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen"
import { toast, ToastContainer } from "react-toastify"

export const Utilisateur = () => {
    const [isRefresh, setIsRefresh] = useState(false)
    const [utilisateur, setUtilisateur] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const fetchUser = async () => {
        try {
            const response = await api.get(`/users`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );
            setUtilisateur(() => {
                return response.data
            })
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => { fetchUser() }, [isRefresh])

    const trigerRefresh = () => { setIsRefresh(!isRefresh) }

    const toastShowSuccess = (message) => {
        toast.success(message)
    }

    const toastShowError = (message) => {
        toast.error(message)
    }

    if (isLoading) return <LoadingScreen />

    return (
        <div className="mt-20">
            <div className="ml-60">
                <UtilisateurCard items={utilisateur} onRefresh={trigerRefresh} sendResponseSuccess={toastShowSuccess} sendResponseError={toastShowError} />
            </div>
            <ToastContainer />
        </div>
    )
} 