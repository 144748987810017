import React, { useState, useEffect, useRef } from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames"
import translate from "../../store/Translation/Langue"
import { UseLanguage } from "../../store/User/language"
import { translations } from "../../store/Translation/Langue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons"
import { useAppStore } from "../../store/User/user"

const pathCorrect = "border-b-4 border-[#06a440] font-normal text-[#06a440] px-2 h-14 flex flex-col justify-center"
const pathIncorrect = "text-gray-700 font-normal"

export const NavbarVisitor = () => {
    const { pathname } = useLocation()
    const language = UseLanguage.use.language()
    const logout = useAppStore.use.logout()
    const changeLanguage = UseLanguage.use.changeLanguage()
    const [selectionLanguage, setSelectionLanguage] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const dropdownRef = useRef(null)
    const menuRef = useRef(null)

    const handleLanguageChange = (lang) => {
        changeLanguage(lang)
        setSelectionLanguage(false)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setSelectionLanguage(false)
        }
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [selectionLanguage, showMenu])


    return (
        <nav className="h-14 fixed flex justify-center border-gray-300 shadow-md bg-white w-full z-20">
            <div className="relative flex h-14 items-center justify-around w-5/6 max-md:justify-between max-md:pl-12 max-sm:pl-1 max-xl:w-full">
                {/*MENU SIDEBAR*/}
                <div className="lg:hidden max-lg:ml-3 flex flex-col justify-center items-around">
                    <button onClick={() => setShowMenu(!showMenu)} className="border border-gray-300 py-1 px-3">
                        <FontAwesomeIcon icon={faBars} className="text-gray-400" />
                    </button>
                </div>
                {/*NAVBAR*/}
                <div className="ml-[-30px] flex gap-x-2 items-center max-sm:ml-4">
                    <img src="/logo.jpeg" alt="LOGO" className="w-9 h-9" />
                    <p className="font-inconsolata font-semibold drop-shadow-2xl text-gray-700">Hay+ </p>
                </div>
                <ul className="flex justify-between items-center w-2/3">
                    <div className="flex items-center justify-center gap-x-10">
                        <li className={`max-lg:hidden ${classNames(pathname === "/" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/" className="text-center">
                                <p>{translate(language, "home")}</p>
                            </Link>
                        </li>
                        <li className={`max-lg:hidden ${classNames(pathname === "/cours" || pathname === "/cours/detail-cours" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/cours">
                                <p className="text-center">{translate(language, "courses")}</p>
                            </Link>
                        </li>
                        <li className={`max-lg:hidden ${classNames(pathname === "/contact" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/contact">
                                <p className="text-center">{translate(language, "contact")}</p>
                            </Link>
                        </li>
                        <li className={`max-lg:hidden ${classNames(pathname === "/certificat" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/certificat">
                                <p className="text-center">{translate(language, "certificate")}</p>
                            </Link>
                        </li>
                        <li className={`max-lg:hidden ${classNames(pathname === "/About" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/About">
                                <p className="text-center">{translate(language, "about")}</p>
                            </Link>
                        </li>
                    </div>
                    <div className="max-sm:right-1 flex justify-around items-center">
                        <li className={`col-span-1 mr-7 max-lg:hidden  ${classNames(pathname === "/recherche" ? "text-primary " : "text-gray-700")}`}>
                            <Link to="/recherche" className="w-full text-center">
                                <FontAwesomeIcon icon={faSearch} className="text-center" />
                            </Link>
                        </li>
                        <div ref={dropdownRef} className="max-md:hidden">
                            <button
                                onClick={() => setSelectionLanguage(!selectionLanguage)}
                                className="text-black/90 rounded-full mt-1 mr-7"
                            >
                                <img src={translate(language, "flag")} alt="language" className="w-4 h-3 object-cover" />
                            </button>
                            {selectionLanguage && (
                                <div className="absolute w-40 bg-white shadow rounded-md border-gray-100 top-9">
                                    {Object.keys(translations).map((langCode) => (
                                        <div key={langCode}>
                                            <button
                                                onClick={() => handleLanguageChange(langCode)}
                                                className="text-gray-600 py-1 flex ml-4 items-center"
                                            >
                                                <img src={translate(langCode, "flag")} alt="language" className="w-4 h-3 mr-2 object-cover" />
                                                <span>{translate(language, langCode)}</span>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <li className="col-span-1 bg-secondary shadow-primaryshadow rounded-md hover:bg-primary py-1 text-center text-white w-36 max-lg:bg-white max-lg:hover:bg-white max-lg:text-secondary max-lg:font-bold max-lg:hover:text-primary">
                            <Link to="/authentification">
                                <p>{translate(language, "login")}</p>
                            </Link>
                        </li>
                    </div>
                </ul>
            </div>
            <div
                ref={menuRef}
                className={`absolute w-1/2 max-sm:-left-[70%] pl-3 top-14 bg-white left-0 transition-all duration-200 linear h-screen`}
                style={{ left: showMenu ? '0px' : "-50vw" }}
            >
                <div className="w-full flex justify-end text-black/80">
                    <button onClick={() => setShowMenu(false)} className="px-4 py-3">
                        <FontAwesomeIcon icon={faXmark} className="text-black/80 w-5 h-5" />
                    </button>
                </div>
                <div className="relative h-full space-y-3 text-gray-700 font-semibold">
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ${pathname === "/" ? 'bg-gray-200' : ''}`}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/utilisateur" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/" ? 'fill-thirdly' : 'fill-gray-700'}`}>
                            <path d="M575.8 255.5c0 18-15 32.1-32 32.1l-32 0 .7 160.2c0 2.7-.2 5.4-.5 8.1l0 16.2c0 22.1-17.9 40-40 40l-16 0c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1L416 512l-24 0c-22.1 0-40-17.9-40-40l0-24 0-64c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32l0 64 0 24c0 22.1-17.9 40-40 40l-24 0-31.9 0c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2l-16 0c-22.1 0-40-17.9-40-40l0-112c0-.9 0-1.9 .1-2.8l0-69.7-32 0c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                        </svg>
                        <Link to="/" className={`font-QuicksandMedium group-hover:text-thirdly ${pathname === "/" ? 'text-[#06a440]' : ''}`}>
                            <p>Accueil</p>
                        </Link>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ${pathname === "/cours" ? 'bg-gray-200' : ''}`}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/cours" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/cours" ? 'fill-thirdly' : 'fill-gray-700 '}`}>
                            <path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                        </svg>
                        <Link to="/cours" className={`font-QuicksandMedium group-hover:text-thirdly ${pathname === "/cours" ? 'text-[#06a440]' : ''}`}>
                            <p>Cours</p>
                        </Link>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ${pathname === "/contact" ? 'bg-gray-200' : ''}`}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/contact" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/contact" ? 'fill-thirdly' : 'fill-gray-700 '}`}>
                            <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
                        </svg>
                        <Link to="/contact" className={`font-QuicksandMedium group-hover:text-thirdly ${pathname === "/contact" ? 'text-[#06a440]' : ''}`}>
                            <p>Contact</p>
                        </Link>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ${pathname === "/certificat" ? 'bg-gray-200' : ''}`}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/certificat" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/certificat" ? 'fill-thirdly' : 'fill-gray-700 '}`}>
                            <path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0L133.9 0c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0L487.4 0C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" />
                        </svg>
                        <Link to="/certificat" className={`font-QuicksandMedium group-hover:text-thirdly ${pathname === "/certificat" ? 'text-[#06a440]' : ''}`}>
                            <p>Certificat</p>
                        </Link>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ${pathname === "/about" ? 'bg-gray-200' : ''}`}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/about" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/about" ? 'fill-thirdly' : 'fill-gray-700 '}`}>
                            <path d="M512 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l448 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16l192 0c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80l-64 0zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0z" />
                        </svg>
                        <Link to="/about" className={`font-QuicksandMedium group-hover:text-thirdly ${pathname === "/about" ? 'text-[#06a440]' : ''}`}>
                            <p>About</p>
                        </Link>
                    </div>
                </div>
                <li className="absolute bottom-14 py-2 w-full flex flex-col">
                    <Link to="/authentification" className="text-center w-full" onClick={() => logout()}>Deconnexion</Link>
                </li>
            </div>
        </nav>
    )
}
