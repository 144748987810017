import React from "react";

export const OSSelect = ({ id, items, state, onChange, error, label }) => {
  return (
    <div className="grid grid-cols-3 gap-x-2 w-full justify-center items-center mt-2">
      <select
        id={id}
        defaultValue={items?.length > 0 ? items[0].id : ""}
        className={`block col-span-2 w-72 text-gray-600 bg-transparent border border-gray-200 p-2 h-10 rounded ${error && "border border-red-500"}`}
        {...state}
        onChange={onChange}
      >
        {(!items || items.length === 0) ? (
          <option value="" disabled>
            Aucun élément
          </option>
        ) : (
          <>
            <option value={''} className="border border-primary">
              Veuillez selectionner le {label}
            </option>
            {items.map((item, index) => {
              const value = item.id;
              const labelOption = item.nom || item.titre || item.name;
              return (
                <option key={index} value={value} className="border border-primary">
                  {labelOption}
                </option>
              );
            })}
          </>
        )}
      </select>
      <label
        htmlFor={id}
        className="absolute text-sm text-grey duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
      >
        {label}
      </label>
      <p className="text-xs text-red-500">{error?.message}</p>
    </div>
  );
};
