import React, { useEffect, useState } from "react";
import { PasserExamenCard } from "../../Components/Cards/Etudiant/PasserExamenCard";
import api from "../../store/Api/Intercepteur";
import { useLocation } from "react-router-dom";

export const PasserExamen = () => {
    const [Examen, setExamen] = useState([]);
    const location = useLocation();
    const { chapitre, id } = location.state;

    const fetchExamen = async () => {
        try {
            const response = await api.get(`/exercices/par-chapitre/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });

            const randomExamen = response.data
                .sort(() => 0.5 - Math.random())
                .slice(0, 10);

            setExamen(randomExamen);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchExamen();
    }, []);

    return (
        <div className="w-full flex flex-col justify-center items-center px-52">
            <PasserExamenCard examen={Examen} chapitre={chapitre} />
        </div>
    );
};
