import React, { useEffect, useState } from "react"
import { OSInput } from "../../Components/Input/OSInput";
import { TextAreaInput } from "../../Components/Input/TextareaInput";
import translate from "../../store/Translation/Langue";
import { UseLanguage } from "../../store/User/language";
import api from "../../store/Api/Intercepteur";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useAppStore } from "../../store/User/user";
import { TYPE } from "../../config/constant";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { FeedBackCardAdmin } from "../../Components/Cards/Admin/FeedBackCard";
import { AucunElement } from "../../Components/Cards/AucunElementCard";
import { useNavigate } from "react-router-dom";

export const FeedBack = () => {
    const language = UseLanguage.use.language()
    const [nom, setNom] = useState('')
    const [titre, setTitre] = useState('')
    const [contenu, setContenu] = useState('')
    const [description, setDescription] = useState('')
    const { type } = useAppStore()
    const navigation = useNavigate()
    const [feedBack, setFeedBack] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isrefresh, setisrefresh] = useState(false)

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("nom", nom);
        formData.append("titre", titre);
        formData.append("contenu", contenu);
        formData.append("description", description);
        try {
            await api.post('/feed-back', formData, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            toast.success("Feedback envoyé avec succès!", {
                onClose: () => {
                    navigation(`/`);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const fetchFeedBack = async () => {
        try {
            const response = await api.get(`/feed-back`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setFeedBack(() => {
                return response.data
            });
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchFeedBack()
    }, [isrefresh])

    const toastShowSuccess = (message) => {
        toast.success(message)
    }

    const toastShowError = (message) => {
        toast.error(message)
    }

    if (isLoading) return <LoadingScreen />

    return (
        <div>
            {type === TYPE.admin ? (
                <div className="relative ml-64 w-3/4 mt-20 min-h-screen">
                    {feedBack.length !== 0 ? (
                        <FeedBackCardAdmin items={feedBack} onRefresh={() => setisrefresh(!isrefresh)} sendResponseSuccess={toastShowSuccess} sendResponseError={toastShowError} />
                    ) : (
                        <div className="w-1/2 ml-[15vw]">
                            <AucunElement item={"feedback"} />
                        </div>
                    )}
                    <ToastContainer />
                </div>
            ) : (
                <div className="w-full h-screen items-center flex justify-center pt-20 mb-10">
                    <form onSubmit={onSubmit} className="w-96 sm:mx-auto">
                        <div class="flex flex-col rounded-t-xl p-2 bg-slate-200">
                            <h2 class="whitespace-nowrap text-center font-semibold text-gray-800 sm:text-xl">{translate(language, 'yaOpinionMatter')}</h2>
                        </div>
                        <div className="py-3 px-3 border-x">
                            <OSInput defaultValue={nom} state={(e) => setNom(e.target.value)} placeholder={translate(language, 'name')} id="nom" />
                            <OSInput defaultValue={titre} state={(e) => setTitre(e.target.value)} placeholder={translate(language, 'title')} id="titre" />
                            <TextAreaInput defaultValue={description} onChange={(e) => setDescription(e.target.value)} label={translate(language, "description")} id="description" />
                            <TextAreaInput defaultValue={contenu} onChange={(e) => setContenu(e.target.value)} label={translate(language, "contenu")} id="contenu" />
                            <button class="my-3 w-full rounded bg-gradient-to-r bg-primary py-1.5 text-base text-white">{translate(language, 'sendFeedBaak')}</button>
                        </div>
                        <button class="bg-slate-200 py-2 rounded-b-xl  w-full text-sm text-gray-600">{translate(language, 'MaybeLater')}</button>
                    </form>
                </div>
            )}
            <ToastContainer />
        </div>
    );
}
