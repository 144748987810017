import React, { useEffect, useState } from "react";
import { PackCardAdmin } from "../../../Components/Cards/Admin/PackCard";
import api from "../../../store/Api/Intercepteur";
import { AucunElement } from "../../../Components/Cards/AucunElementCard";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const Pack = () => {
    const [pack, setPack] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isrefresh, setisrefresh] = useState(false)

    const fetchPack = async () => {
        try {
            const response = await api.get(`/packs`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setPack(() => {
                return response.data
            });
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchPack()
    }, [isrefresh])

    const triggerRefresh = () => {
        setisrefresh(!isrefresh)
    }

    const toastShowSuccess = (message) => {
        toast.success(message)
    }

    const toastShowError = (message) => {
        toast.error(message)
    }

    if (isLoading) return <LoadingScreen />

    return (
        <div className="relative ml-64 w-3/4 mt-20 min-h-screen">
            <PackCardAdmin isButton onRefresh={triggerRefresh} />
            {pack.length !== 0 ? (
                <PackCardAdmin items={pack} onRefresh={triggerRefresh} sendResponseSuccess={toastShowSuccess} sendResponseError={toastShowError} />
            ) : (
                <div className="w-1/2 ml-[15vw]">
                    <AucunElement item={"pack"} />
                </div>
            )}
            <ToastContainer />
        </div>
    )
}