import React, { useState } from "react";
import translate from "../../store/Translation/Langue";
import { UseLanguage } from "../../store/User/language";

export const Certificat = () => {
    const [code, setCode] = useState(["", "", "", "", ""]);
    let val = ''
    const language = UseLanguage.use.language()

    const handleChange = (index, e) => {
        const value = e.target.value;
        if (value.length > 1) return;

        const newCode = [...code];
        newCode[index] = value;
        if (value && index < code.length - 1) {
            const nextInput = document.querySelector(`#input-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
        if (!value && index > 0) {
            const prevInput = document.querySelector(`#input-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
        setCode(newCode);
    };

    const handleSearch = (e) => {
        e.preventDefault()
        for (let index = 0; index < 5; index++) {
            val = val + code[index]
        }
        const url = `/certificat/${val}`;
        window.open(url, '_blank');
    }

    return (
        <div className="relative h-[90vh] mt-14 text-black/80 px-40 flex flex-col justify-center items-center gap-x-40 bg-opacity-50 overflow-hidden max-lg:px-10 max-md:px-1 max-md:h-full max-md:overflow-y-auto">
            <div className="w-4/5 rounded-lg shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-5 max-md:px-2 mx-md:mt-10 max-md:w-[90%]">
                <div className="relative flex gap-x-20 w-full justify-center max-md:gap-x-5">
                    <svg
                        className="w-10 h-10 animate-rotate mt-10"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polygon
                            points="50,5 90,30 70,90 30,90 10,30"
                            fill="#00cc99"
                            stroke="#009977"
                            strokeWidth="3"
                        />
                    </svg>
                    <svg
                        className="w-10 h-10 animate-rotate mt-4"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polygon
                            points="50,15 61,40 88,40 66,58 74,84 50,69 26,84 34,58 12,40 39,40"
                            fill="#ffcc4d"
                            stroke="#e5b236"
                            strokeWidth="3"
                        />
                    </svg>
                    <svg
                        className="w-10 h-10 animate-rotate"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polygon
                            points="50,10 90,50 50,90 10,50"
                            fill="#7FDB70"
                            stroke="#41B82E"
                            strokeWidth="3"
                        />
                    </svg>
                    <svg
                        className="w-10 h-10 animate-rotate mt-4"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polygon
                            points="50,10 90,50 50,90 10,50"
                            fill="#ffcc4d"
                            stroke="#e5b236"
                            strokeWidth="3"
                        />
                        <polygon
                            points="10,10 50,50 90,10"
                            fill="#ffcc4d"
                            stroke="#e5b236"
                            strokeWidth="3"
                        />
                    </svg>
                    <svg
                        className="w-10 h-10 animate-rotate mt-10"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polygon
                            points="50,5 75,90 25,90"
                            fill="#00cc99"
                            stroke="#009977"
                            strokeWidth="3"
                        />
                    </svg>
                </div>
                <h1 className="text-2xl py-5 font-bold text-center">{translate(language, 'AuthenticCertificate')}</h1>
                <p className="text-center">{translate(language, 'gettingCertificateDescription')}</p>
                <form onSubmit={handleSearch} className="relative mt-5 flex flex-col items-center">
                    <p className="text-xl font-bold my-5">{translate(language, 'certificatCode')}</p>
                    <div className="relative flex items-center justify-center w-4/5 gap-2">
                        {code?.map((char, index) => (
                            <input
                                key={index}
                                id={`input-${index}`}
                                type="text"
                                maxLength="1"
                                value={char}
                                onChange={(e) => handleChange(index, e)}
                                className="w-12 h-12 text-center border border-gray-300 rounded-lg focus:outline-none focus:ring-secondary focus:border-green-600"
                            />
                        ))}
                    </div>
                    <button
                        // disabled={val.length < 3}
                        type="submit"
                        className="z-10 mt-4 text-center p-3 min-w-40 rounded-lg text-sm font-bold text-white bg-primary"
                    >
                        {translate(language, 'searchCertificate')}
                    </button>
                </form>
            </div>
        </div>
    );
};
