import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware';
import api from "../Api/Intercepteur";

const createSelectors = (_store) => {
    let store = _store;
    store.use = {};
    for (let k of Object.keys(store.getState())) {
        store.use[k] = () => store((s) => s[k]);
    }
    return store;
};
const getUser = async () => {
    try {
        const token = localStorage.getItem("token");
        const response = await api.get(
            `/me`,
            { headers: { Authorization: `Bearer ${token}` } }
        );

        const userconnected = response.data.user.type_utilisateur;
        localStorage.setItem("info", JSON.stringify(response.data.user));

        if (userconnected) {
            return { type: response.data.user };
        }
    } catch (error) {
        console.error("Error fetching user data:", error);
    }
};

export const useAppStore = createSelectors(
    create(
        persist(
            (set, get) => ({
                type: "",
                user: {
                    token: null,
                    info: null,
                },

                async register(name, email, password, type_utilisateur, niveau) {
                    try {
                        await api.post(
                            `/register`,
                            {
                                name: name,
                                email: email,
                                password: password,
                                type_utilisateur: type_utilisateur,
                                niveau: niveau
                            }
                        );
                        // const { token } = response.data;

                        // if (token) {
                        //     console.log("token :", token);
                        // }
                        return { success: true }
                    } catch (error) {
                        return { succes: false }
                    }
                },

                async login(email, password) {
                    try {
                        const response = await api.post(
                            `/login`,
                            { email, password }
                        );
                        const { token } = response.data;

                        if (token) {
                            localStorage.setItem('token', token)
                            const userTypeResult = await getUser();
                            const typeUser = userTypeResult?.type.type_utilisateur;
                            const infoUser = userTypeResult?.type

                            set({ type: typeUser, user: { token: token, info: infoUser } });
                            return { success: true };
                        }
                    } catch (error) {
                        if (error.response && error.response.status === 500) {
                            return { success: false, status: 500 };
                        } else {
                            return { success: false };
                        }
                    }
                },

                check: async () => {
                    try {
                        const response = await api.get(
                            `/me`, {
                            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                        });
                        const userconnected = response.data.user.type_utilisateur;

                        if (userconnected) {
                            set({ type: userconnected, user: { info: response.data.user } });
                            return { type: userconnected };
                        }
                    } catch (error) {
                        localStorage.removeItem("token");
                        return { type: null }
                    }
                },

                logout() {
                    try {
                        localStorage.clear();
                        set({ type: "", user: { info: null, token: null } });
                        return { success: true };
                    } catch (error) {
                        console.log(error);
                        return { success: false, error: error.message };
                    }
                }
            }),
            {
                name: 'token',
                storage: createJSONStorage(() => sessionStorage),
            }
        )
    )
);
