import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import api, { baseUrlImage } from "../../../store/Api/Intercepteur";
import DeleteCard from "../../DeleteConfirmation/DeleteCard";

export const CoursCardAdmin = ({ items, isButton, onRefresh, sendResponseSuccess, sendResponseError }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate()
    const [Id, setId] = useState(null)
    const modalSuppressionRef = useRef(null);
    const [showSuppressionModal, setShowSuppressionModal] = useState(false)
    const modalRef = useRef()
    const [showOptionIndex, setShowOptionIndex] = useState(null)
    const [stateOption, setStateOption] = useState(false)

    const elippsisClicked = (index) => {
        setShowOptionIndex(index)
        setStateOption(!stateOption)
    }

    const handleEditClick = (item) => {
        setSelectedItem(item);
        if (selectedItem) {
            navigate("/cours/modification-cours", { state: { selectedItem } });
        }
    };

    const handleDetailsClicked = (selectedItem) => {
        navigate('/cours/view-cours', { state: { selectedItem } })
    }

    useEffect(() => {
    }, [selectedItem]);

    const handleDeleteClicked = (id) => {
        setShowSuppressionModal(true);
        setId(id)
    }

    const confirmDelete = async () => {
        try {
            await api.delete(`/cours/${Id}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
            setShowSuppressionModal(false)
            sendResponseSuccess("Cours supprimé avec succès!")
            onRefresh();
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setShowSuppressionModal(false)
                return sendResponseError("Impossible de supprimé ce cours tant qu'il y a des étudiants qui le suit!")
            }
            console.log(error)
        }
    }

    const cancelDelete = async () => {
        setShowSuppressionModal(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalSuppressionRef.current && !modalSuppressionRef.current.contains(event.target)) {
                setShowSuppressionModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowOptionIndex(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="w-full">
            {!isButton ? (
                <div className="w-full flex justify-start flex-wrap gap-x-20 gap-y-10">
                    {items?.map((item, index) => (
                        <div className="relative">
                            <button onClick={() => handleDetailsClicked(item)} key={index} className="relative rounded-lg shadow-cardShadow bg-white pt-5 w-72">
                                <div
                                    className="rounded-lg flex flex-col items-center bg-white shadow-secondary-1 dark:bg-surface-dark">
                                    <img
                                        className="rounded-t-lg"
                                        src={`${baseUrlImage}/storage/${item.image}`}
                                        alt="" />
                                    <div className="p-6 text-surface">
                                        <h5 className="mb-2 text-xl font-medium leading-tight text-blue">{item.nom}</h5>
                                        <p className="mb-4 text-base overflow-hidden"
                                            style={{
                                                height: "4.5em",
                                            }}
                                        >{item.description}</p>
                                        <button
                                            type="button"
                                            className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                                            data-twe-ripple-init
                                            data-twe-ripple-color="light">
                                            Read more
                                        </button>
                                    </div>
                                </div>
                            </button>
                            <div className="absolute top-3 right-3">
                                <button onClick={() => elippsisClicked(index)} className="w-40 flex justify-end pb-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" width={16} height={16}>
                                        <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                                    </svg>
                                </button>
                                {showOptionIndex === index && stateOption && (
                                    <div ref={modalRef} className="absolute top-2 bg-white flex flex-col rounded right-3 shadow-cardShadow w-16">
                                        <button
                                            className="text-green-700/80 hover:bg-gray-200 py-2"
                                            onClick={() => handleEditClick(item)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} color="green" />
                                        </button>
                                        <button
                                            className="text-green-700/80 hover:bg-gray-200 py-2"
                                            onClick={() => handleDeleteClicked(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} color="red" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex justify-end mr-1 mt-5 mb-5">
                    {/* <Link to={"/cours/nouveau-cours"} className="flex items-center justify-around hover:bg-green-100 bg-gray-200 px-6 rounded-lg py-2">
                        <FontAwesomeIcon icon={faPlusCircle} className="text-gray-700" />
                        <p className="text-gray-700 ml-1">Nouveau cours</p>
                    </Link> */}
                    <Link to={"/cours/nouveau-cours"}
                        type="button"
                        className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    >
                        Nouveau cours
                    </Link>
                </div>
            )}
            <Modal
                isOpen={showSuppressionModal}
                onRequestClose={() => setShowSuppressionModal(false)}
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                overlayclassName="fixed inset-0 bg-black bg-opacity-50"
                shouldCloseOnOverlayClick={true}
            >
                <div ref={modalSuppressionRef} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-20 ml-[20vw]">
                    <DeleteCard onCancel={cancelDelete} onConfirm={confirmDelete} />
                </div>
            </Modal>
        </div>
    )
}