import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import translate from "../../store/Translation/Langue";
import { UseLanguage } from "../../store/User/language";

export const SearchBar = ({ state, placeholder, onClick }) => {
  const language = UseLanguage.use.language()

  return (
    <div className="flex items-center justify-end w-full">
      <form action="" onSubmit={onClick} className="w-full relative flex items-center justify-end ">
        <input
          type="search"
          placeholder={placeholder || translate(language, "search")}
          required
          onChange={state}
          defaultValue={null}
          className="absolute w-full p-4 text-sm text-gray-900 rounded-full border border-gray-300 focus:outline-none focus:ring-secondary focus:border-green-600"
        />
        <button
          type="submit"
          className="z-10 rounded-full mr-2 text-center w-10 h-10 text-sm font-bold text-white bg-primary"
        >{/* h-full w-14*/}
          <FontAwesomeIcon icon={faSearch} className="text-white" />
          {/* Rechercher */}
        </button>
      </form>
    </div>
  );
};
