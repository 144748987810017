import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faCalendar, faClock, faEdit } from "@fortawesome/free-regular-svg-icons";
import Modal from "react-modal";
import { Auth } from "../../../Pages/Authentification/Auth";
import { useAppStore } from "../../../store/User/user";
import translate from "../../../store/Translation/Langue";
import { UseLanguage } from "../../../store/User/language";
import api, { baseUrlImage } from "../../../store/Api/Intercepteur";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import DeleteCard from "../../DeleteConfirmation/DeleteCard";

Modal.setAppElement("#root");

export const CoursCard = ({ items, isSuivi, onRefresh }) => {
    const type = useAppStore.use.type()
    const language = UseLanguage.use.language()
    const navigate = useNavigate()
    const [showSuppressionModal, setShowSuppressionModal] = useState(false)
    const modalSuppressionRef = useRef(null);
    const [Id, setId] = useState(null)

    const DetailsCoursClicked = (selectedItem, isSuivi) => {
        navigate('/cours/detail-cours', { state: { selectedItem, isSuivi } })
    }

    const handleEdit = (selectedItem) => {
        navigate('/cours/modification-cours', { state: { selectedItem } })
    }

    const handleDelete = (id) => {
        setShowSuppressionModal(true);
        setId(id)
    }

    const confirmDelete = async () => {
        try {
            await api.delete(`/cours/${Id}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
            setShowSuppressionModal(false)
            toast.success("Cours supprimé avec succès!")
            onRefresh()
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setShowSuppressionModal(false)
                return toast.error("Impossible de supprimé ce cours tant qu'il y a des étudiants qui le suit!")
            }
            console.log(error)
        }
    }

    const cancelDelete = async () => {
        setShowSuppressionModal(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalSuppressionRef.current && !modalSuppressionRef.current.contains(event.target)) {
                setShowSuppressionModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="max-sm:w-full">
            <div className="group relative rounded-2xl h-[520px] bg-white border border-gray-200 w-80 mb-10 shadow-md hover:-translate-y-3 transition duration-500 max-md:w-full">
                {type === 'administrateur' && (
                    <div className="absolute group-hover:block hidden bg-transparent right-2 top-5 z-10 shadow-cardShadow rounded px-2 py-1">
                        <div className="flex flex-col gap-y-2">
                            <button onClick={() => handleEdit(items)} className="p-1 text-primary">
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button onClick={() => handleDelete(items)} className="p-4 text-red-500">
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <Modal
                                isOpen={showSuppressionModal}
                                onRequestClose={() => setShowSuppressionModal(false)}
                                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                                overlayclassName="fixed inset-0 bg-black bg-opacity-50"
                                shouldCloseOnOverlayClick={true}
                            >
                                <div ref={modalSuppressionRef} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-20 ml-[20vw]">
                                    <DeleteCard onCancel={cancelDelete} onConfirm={confirmDelete} />
                                </div>
                            </Modal>
                        </div>
                    </div>
                )}
                <button onClick={() => DetailsCoursClicked(items, isSuivi)} className="max-md:w-full">
                    <div className="absolute flex flex-col justify-center items-center bg-primary px-4 py-1 text-white hover:bg-secondary hover:text-blue-950 mt-2 ml-2 rounded-br-2xl rounded-tl-2xl">
                        <p>{translate(language, "courses")}</p>
                    </div>
                    <div className="w-80 max-sm:w-full">
                        <img src={items.image ? `${baseUrlImage}/storage/${items.image}` : "https://lms.rocket-soft.org/store/867/How%20to%20Travel%20Around%20the%20World%20on%20a%20Budget.jpg"} alt="images" className="object-cover w-80 h-60 max-md:w-full rounded-t-2xl" />
                    </div>
                    <div className="mt-3 px-5 flex items-center">
                        <img src={`${items.photo ? items.photo : '/image/profile.png'}`} alt="user" className="rounded-full w-11 h-11" />
                        <Link to="/otherProfile" className="ml-3 text-[#343a40]">{items.enseignant}</Link>
                    </div>
                    <div className="flex flex-col justify-between px-5">
                        <div className="w-full text-left mt-3 h-10">
                            <Link to="/cours/view-cours" className="text-blue font-bold text-xl">{items.nom}</Link>
                        </div>
                        <span className="text-black/70 mt-5 w-full text-left">
                            <span>{translate(language, "in")} </span>
                            <Link to="/matiere/view-matiere" className="underline decoration-gray-700/80">{items.categorie}</Link>
                        </span>
                        <div className="flex justify-between mt-5">
                            <div>
                                <FontAwesomeIcon icon={faClock} className="text-blue-950 mr-2" />
                                <span className="text-blue-950">50 {translate(language, "hour")}</span>
                            </div>
                            <span className="text-blue-950 flex justify-between items-center">
                                <FontAwesomeIcon icon={faCalendar} className="text-blue-950 mr-2" />
                                <span>05/06/2024</span>
                            </span>
                        </div>
                    </div>
                    {type === "etudiant" ? (
                        <div className="p-3 my-3">
                            {isSuivi ? (
                                <div>
                                    <p className="text-secondary font-extrabold text-xl">{translate(language, "followed")}</p>
                                </div>
                            ) : (
                                <div>
                                    <p className="text-secondary font-extrabold text-xl">${items.cout}</p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="p-3 my-3">
                            <p className="text-secondary font-extrabold text-xl">${items.cout}</p>
                        </div>
                    )}
                </button>
            </div>
        </div>
    );
};
