import translate from "../../store/Translation/Langue"
import { UseLanguage } from "../../store/User/language"

export const About = () => {
    const language = UseLanguage.use.language()

    return (
        <div class="sm:flex items-center max-w-screen-xl px-40 h-[100vh] max-md:px-3">
            <div class="sm:w-1/2 p-10 max-md:mt-3">
                <div class="image object-center text-center">
                    <img src="https://i.imgur.com/WbQnbas.png" alt="" />
                </div>
            </div>
            <div class="sm:w-1/2 p-5">
                <div class="text">
                    <span class="text-gray-500 border-b-2 border-primary uppercase">{translate(language, 'AboutUs')}</span>
                    <h2 class="my-4 font-bold text-3xl  sm:text-4xl text-black/80">{translate(language, 'about')} <span class="text-primary">Hay+</span>
                    </h2>
                    <p class="text-gray-700 max-md:text-justify">{translate(language, 'aboutHayPlus')}</p>
                </div>
            </div>
        </div>
    )
}